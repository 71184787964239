import React, { useState, useEffect } from 'react';
import "./Make.css";
import { firestore, storage, auth } from '../../firebase/firebaseConfig';
import { collection, getDocs, doc, setDoc, getDoc, query, where, onSnapshot, updateDoc } from '@firebase/firestore';
import { ref, uploadBytes } from "firebase/storage";
import { onAuthStateChanged } from 'firebase/auth';
import { Button, Collapse, ColorPicker, Modal, Select, Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import JoditEditor from 'jodit-react';

function Make () {
    let [ companyArray, setCompanyArray ] = useState( [] );
    let [ variantArray, setVariantArray ] = useState( [
        "All"
    ] );
    let [ standardAccessoryArray, setStandardAccessoryArray ] = useState( [] );
    let [ standardAccessories, setStandardAccessories ] = useState( [] );
    let [ additionalAccessoryArray, setAdditionalAccessoryArray ] = useState( [] );
    let [ additionalAccessories, setAdditionalAccessories ] = useState( [] );
    let [ addDetails, setAddDetails ] = useState( {} );
    let [ colorObject, setColorObject ] = useState( {
        variant: "All"
    } );
    let [ variantName, setVariantName ] = useState( "" );
    let [ vehicleType, setVehicleType ] = useState( undefined );
    const [ messageApi, contextHolder ] = message.useMessage();
    let [ specifications, setSpecifications ] = useState( {} );
    let [ petrolModal, setPetrolModal ] = useState( false );
    let [ confirmLoading, setConfirmLoading ] = useState( false );
    let [ imagesModal, setImagesModal ] = useState( false );
    let [ editImagesModal, setEditImagesModal ] = useState( false );
    const [ previewOpen, setPreviewOpen ] = useState( false );
    const [ previewImage, setPreviewImage ] = useState( '' );
    const [ previewTitle, setPreviewTitle ] = useState( '' );
    const [ fileList, setFileList ] = useState( [] );
    const [ editModalObject, setEditModalObject ] = useState( {} );
    const [ editFileList, setEditFileList ] = useState( [] );
    const [ schemes, setSchemes ] = useState( [] );
    const [ selectedSchemes, setSelectedSchemes ] = useState( [] );
    const [ specificationsDetails, setSpecificationsDetails ] = useState( {} );

    const items = [
        {
            key: '1',
            label: 'List Of Charges',
            children: <div className='making-container-top-container' style={{ alignItems: "flex-start", width: "100%", maxWidth: "100%", borderRadius: "0 0 0.75rem 0.75rem" }}>
                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                    allowClear
                    mode='multiple'
                    style={{
                        width: '400px',
                    }}
                    value={selectedSchemes.map( ( element ) => JSON.stringify( element ) ) ?? []}
                    options={schemes.map( ( element ) => ( { label: element.bank + " - " + element.scheme, value: JSON.stringify( element ) } ) )}
                    onChange={( newValue ) => {
                        let array = newValue.map( ( element ) => JSON.parse( element ) );
                        setSelectedSchemes( [ ...array ] );
                    }}
                    placeholder='Select Schemes'
                    maxTagCount='responsive'
                />
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    width: "calc( 100% - 420px )",
                    maxHeight: "400px",
                    overflowY: "scroll",
                    flexWrap: "nowrap"
                }}>
                    {selectedSchemes.map( ( element, index ) => (
                        <div style={{
                            display: "flex",
                            gap: "1rem"
                        }}>
                            <input type="text" value={element.bank + " - " + element.scheme} style={{ width: "100%" }} readOnly />
                        </div>
                    ) )}
                </div>
            </div>,
        },
    ];

    const mumbai = [
        {
            key: '1',
            label: <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span>Mumbai</span>
                {variantArray.length > 1 && <Button style={{ color: "black", fontWeight: "600" }} onClick={event => addModel( event )}>Add Model</Button>}
            </div>,
            children: <div className='all-make-entries-container' style={{ width: "100%", maxWidth: '100%', maxHeight: '500px', backgroundColor: "black" }}>
                {
                    Object.keys( addDetails.cities && addDetails.cities[ "Mumbai" ] ? addDetails.cities[ "Mumbai" ] : {} ).map( ( variant, index ) => (
                        addDetails.cities[ "Mumbai" ][ variant ].colors.map( ( color, index1 ) => (
                            <div className='all-make-entry-container'>
                                <input type="text" value={"Mumbai"} readOnly />
                                <input type="text" value={addDetails.make} readOnly />
                                <input type="text" value={addDetails.model} readOnly />
                                <input type="text" value={variant} readOnly />
                                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={addDetails.cities[ "Mumbai" ][ variant ].accessories_array}
                                    options={standardAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        addDetails.cities[ "Mumbai" ][ variant ].accessories_array = newValue;
                                        setAddDetails( { ...addDetails } );
                                    }}
                                    placeholder='Select Standard Accessories'
                                    maxTagCount='responsive'
                                />
                                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={addDetails.cities[ "Mumbai" ][ variant ].additional_array}
                                    options={additionalAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        addDetails.cities[ "Mumbai" ][ variant ].additional_array = newValue;
                                        setAddDetails( { ...addDetails } );
                                    }}
                                    placeholder='Select Additional Accessories'
                                    maxTagCount='responsive'
                                />
                                <input type="text" value={color.name} readOnly />
                                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    style={{
                                        width: '150px',
                                    }}
                                    value={color.status}
                                    options={[
                                        { label: "Active", value: "Active" },
                                        { label: "Out Of Stock", value: "Out Of Stock" },
                                        { label: "Discontinued", value: "Discontinued" },
                                    ]}
                                    onChange={( newValue ) => {
                                        Object.keys( addDetails.cities ).forEach( ( element ) => {
                                            addDetails.cities[ element ][ variant ].colors[ index1 ].status = newValue;
                                        } )
                                        setAddDetails( { ...addDetails } );
                                    }}
                                    placeholder='Select Status'
                                />
                                <ColorPicker showText format='hex' value={color.color ?? ""} onChange={event => {
                                    Object.keys( addDetails.cities ).forEach( ( element ) => {
                                        addDetails.cities[ element ][ variant ].colors[ index1 ].color = event.toHexString();
                                    } )
                                    setAddDetails( { ...addDetails } );
                                }} />
                                <input type="text" value={color.price} onChange={event => {
                                    if ( event.target.value !== "" ) {
                                        addDetails.cities[ "Mumbai" ][ variant ].colors[ index1 ].price = event.target.value;
                                        setAddDetails( { ...addDetails } );
                                    } else {
                                        error7();
                                    }
                                }} />
                                <Button onClick={event => document.querySelector( ".edit-make-image-upload-"+ variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" ) ).click()}>{color.imageName}</Button>
                                <input type="file" className={"edit-make-image-upload-"+ variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" )} style={{ display: "none" }} onChange={event => {
                                    if ( event.target.files[ 0 ] ) {
                                        Object.keys( addDetails.cities ).forEach( ( element ) => {
                                            addDetails.cities[ element ][ variant ].colors[ index1 ].image = event.target.files[ 0 ];
                                            addDetails.cities[ element ][ variant ].colors[ index1 ].imageName = event.target.files[ 0 ].name;
                                        } )
                                        setAddDetails( { ...addDetails } );
                                    }
                                }} />
                                <Button onClick={event => {
                                    setEditFileList( color.imageNameArray?.map( ( colorImage ) => ( {
                                        name: colorImage,
                                        url: ( "https://ik.imagekit.io/kdllkkkhz/v0/b/emi-calculator-fbc7a.appspot.com/o/Make%2F" + addDetails.make + "%2FModels%2F" + addDetails.model + "%2FCities%2FMumbai%2FVariants%2F" + variant + "%2FColors%2F" + color.name + "%2F" + colorImage + "?alt=media&token=178c1203-094c-4f52-b4ff-febb67b8f3d1" ).replaceAll( " ", "%20" ).replaceAll( "+", "%2B" ).replaceAll( "&amp;", "%26" ),
                                    } ) ) )
                                    editModalObject.variant = variant;
                                    editModalObject.color = index1;
                                    setEditModalObject( { ...editModalObject } );
                                    setEditImagesModal( true )
                                }}>
                                    Edit Images
                                </Button>
                                <Button onClick={event => {
                                    setSpecifications( { ...addDetails.cities[ "Mumbai" ][ variant ].specifications ?? {} } );
                                    let object = {
                                        variant: variant,
                                        type: "Edit"
                                    }
                                    setSpecificationsDetails( { ...object } )
                                    setPetrolModal( true );
                                }}>Edit Specifications</Button>
                                <Button danger onClick={event => {
                                    Object.keys( addDetails.cities ).forEach( ( element ) => {
                                        addDetails.cities[ element ][ variant ].colors.splice( index1, 1 );
                                    } )
                                    setAddDetails( { ...addDetails } );
                                }}>Delete Color</Button>
                                <Button danger onClick={event => {
                                    Object.keys( addDetails.cities ).forEach( ( element ) => {
                                        delete addDetails.cities[ element ][ variant ];
                                    } )
                                    variantArray.splice( index, 1 );
                                    setVariantArray( [ ...variantArray ] );
                                    setAddDetails( { ...addDetails } );
                                }}>Delete Variant</Button>
                            </div>
                        ) )
                    ) )}
            </div>
        },
    ];

    const newDelhi = [
        {
            key: '1',
            label: <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span>New Delhi</span>
                {variantArray.length > 1 && <Button style={{ color: "black", fontWeight: "600" }} onClick={event => addModel( event )}>Add Model</Button>}
            </div>,
            children: <div className='all-make-entries-container' style={{ width: "100%", maxWidth: '100%', maxHeight: '500px', backgroundColor: "black" }}>
                {
                    Object.keys( addDetails.cities && addDetails.cities[ "New Delhi" ] ? addDetails.cities[ "New Delhi" ] : {} ).map( ( variant, index ) => (
                        addDetails.cities[ "New Delhi" ][ variant ].colors.map( ( color, index1 ) => (
                            <div className='all-make-entry-container'>
                                <input type="text" value={"New Delhi"} readOnly />
                                <input type="text" value={addDetails.make} readOnly />
                                <input type="text" value={addDetails.model} readOnly />
                                <input type="text" value={variant} readOnly />
                                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={addDetails.cities[ "New Delhi" ][ variant ].accessories_array}
                                    options={standardAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        addDetails.cities[ "New Delhi" ][ variant ].accessories_array = newValue;
                                        setAddDetails( { ...addDetails } );
                                    }}
                                    placeholder='Select Standard Accessories'
                                    maxTagCount='responsive'
                                />
                                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={addDetails.cities[ "New Delhi" ][ variant ].additional_array}
                                    options={additionalAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        addDetails.cities[ "New Delhi" ][ variant ].additional_array = newValue;
                                        setAddDetails( { ...addDetails } );
                                    }}
                                    placeholder='Select Additional Accessories'
                                    maxTagCount='responsive'
                                />
                                <input type="text" value={color.name} readOnly />
                                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    style={{
                                        width: '150px',
                                    }}
                                    value={color.status}
                                    options={[
                                        { label: "Active", value: "Active" },
                                        { label: "Out Of Stock", value: "Out Of Stock" },
                                        { label: "Discontinued", value: "Discontinued" },
                                    ]}
                                    onChange={( newValue ) => {
                                        Object.keys( addDetails.cities ).forEach( ( element ) => {
                                            addDetails.cities[ element ][ variant ].colors[ index1 ].status = newValue;
                                        } )
                                        setAddDetails( { ...addDetails } );
                                    }}
                                    placeholder='Select Status'
                                />
                                <ColorPicker showText format='hex' value={color.color ?? ""} onChange={event => {
                                    Object.keys( addDetails.cities ).forEach( ( element ) => {
                                        addDetails.cities[ element ][ variant ].colors[ index1 ].color = event.toHexString();
                                    } )
                                    setAddDetails( { ...addDetails } );
                                }} />
                                <input type="text" value={color.price} onChange={event => {
                                    if ( event.target.value !== "" ) {
                                        addDetails.cities[ "New Delhi" ][ variant ].colors[ index1 ].price = event.target.value;
                                        setAddDetails( { ...addDetails } );
                                    } else {
                                        error7();
                                    }
                                }} />
                                <Button onClick={event => document.querySelector( ".edit-make-image-upload-"+ variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" ) ).click()}>{color.imageName}</Button>
                                <input type="file" className={"edit-make-image-upload-"+ variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" )} style={{ display: "none" }} onChange={event => {
                                    if ( event.target.files[ 0 ] ) {
                                        Object.keys( addDetails.cities ).forEach( ( element ) => {
                                            addDetails.cities[ element ][ variant ].colors[ index1 ].image = event.target.files[ 0 ];
                                            addDetails.cities[ element ][ variant ].colors[ index1 ].imageName = event.target.files[ 0 ].name;
                                        } )
                                        setAddDetails( { ...addDetails } );
                                    }
                                }} />
                                <Button onClick={event => {
                                    setEditFileList( color.imageNameArray?.map( ( colorImage ) => ( {
                                        name: colorImage,
                                        url: ( "https://ik.imagekit.io/kdllkkkhz/v0/b/emi-calculator-fbc7a.appspot.com/o/Make%2F" + addDetails.make + "%2FModels%2F" + addDetails.model + "%2FCities%2FNew Delhi%2FVariants%2F" + variant + "%2FColors%2F" + color.name + "%2F" + colorImage + "?alt=media&token=178c1203-094c-4f52-b4ff-febb67b8f3d1" ).replaceAll( " ", "%20" ).replaceAll( "+", "%2B" ).replaceAll( "&amp;", "%26" ),
                                    } ) ) )
                                    editModalObject.variant = variant;
                                    editModalObject.color = index1;
                                    setEditModalObject( { ...editModalObject } );
                                    setEditImagesModal( true )
                                }}>
                                    Edit Images
                                </Button>
                                <Button onClick={event => {
                                    setSpecifications( { ...addDetails.cities[ "Mumbai" ][ variant ].specifications ?? {} } );
                                    let object = {
                                        variant: variant,
                                        type: "Edit"
                                    }
                                    setSpecificationsDetails( { ...object } )
                                    setPetrolModal( true );
                                }}>Edit Specifications</Button>
                                <Button danger onClick={event => {
                                    Object.keys( addDetails.cities ).forEach( ( element ) => {
                                        addDetails.cities[ element ][ variant ].colors.splice( index1, 1 );
                                    } )
                                    setAddDetails( { ...addDetails } );
                                }}>Delete Color</Button>
                                <Button danger onClick={event => {
                                    Object.keys( addDetails.cities ).forEach( ( element ) => {
                                        delete addDetails.cities[ element ][ variant ];
                                    } )
                                    variantArray.splice( index, 1 );
                                    setVariantArray( [ ...variantArray ] );
                                    setAddDetails( { ...addDetails } );
                                }}>Delete Variant</Button>
                            </div>
                        ) )
                    ) )}
            </div>
        },
    ];

    const chennai = [
        {
            key: '1',
            label: <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span>Chennai</span>
                {variantArray.length > 1 && <Button style={{ color: "black", fontWeight: "600" }} onClick={event => addModel( event )}>Add Model</Button>}
            </div>,
            children: <div className='all-make-entries-container' style={{ width: "100%", maxWidth: '100%', maxHeight: '500px', backgroundColor: "black" }}>
                {
                    Object.keys( addDetails.cities && addDetails.cities[ "Chennai" ] ? addDetails.cities[ "Chennai" ] : {} ).map( ( variant, index ) => (
                        addDetails.cities[ "Chennai" ][ variant ].colors.map( ( color, index1 ) => (
                            <div className='all-make-entry-container'>
                                <input type="text" value={"Chennai"} readOnly />
                                <input type="text" value={addDetails.make} readOnly />
                                <input type="text" value={addDetails.model} readOnly />
                                <input type="text" value={variant} readOnly />
                                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={addDetails.cities[ "Chennai" ][ variant ].accessories_array}
                                    options={standardAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        addDetails.cities[ "Chennai" ][ variant ].accessories_array = newValue;
                                        setAddDetails( { ...addDetails } );
                                    }}
                                    placeholder='Select Standard Accessories'
                                    maxTagCount='responsive'
                                />
                                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={addDetails.cities[ "Chennai" ][ variant ].additional_array}
                                    options={additionalAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        addDetails.cities[ "Chennai" ][ variant ].additional_array = newValue;
                                        setAddDetails( { ...addDetails } );
                                    }}
                                    placeholder='Select Additional Accessories'
                                    maxTagCount='responsive'
                                />
                                <input type="text" value={color.name} readOnly />
                                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    style={{
                                        width: '150px',
                                    }}
                                    value={color.status}
                                    options={[
                                        { label: "Active", value: "Active" },
                                        { label: "Out Of Stock", value: "Out Of Stock" },
                                        { label: "Discontinued", value: "Discontinued" },
                                    ]}
                                    onChange={( newValue ) => {
                                        Object.keys( addDetails.cities ).forEach( ( element ) => {
                                            addDetails.cities[ element ][ variant ].colors[ index1 ].status = newValue;
                                        } )
                                        setAddDetails( { ...addDetails } );
                                    }}
                                    placeholder='Select Status'
                                />
                                <ColorPicker showText format='hex' value={color.color ?? ""} onChange={event => {
                                    Object.keys( addDetails.cities ).forEach( ( element ) => {
                                        addDetails.cities[ element ][ variant ].colors[ index1 ].color = event.toHexString();
                                    } )
                                    setAddDetails( { ...addDetails } );
                                }} />
                                <input type="text" value={color.price} onChange={event => {
                                    if ( event.target.value !== "" ) {
                                        addDetails.cities[ "Chennai" ][ variant ].colors[ index1 ].price = event.target.value;
                                        setAddDetails( { ...addDetails } );
                                    } else {
                                        error7();
                                    }
                                }} />
                                <Button onClick={event => document.querySelector( ".edit-make-image-upload-"+ variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" ) ).click()}>{color.imageName}</Button>
                                <input type="file" className={"edit-make-image-upload-"+ variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" )} style={{ display: "none" }} onChange={event => {
                                    if ( event.target.files[ 0 ] ) {
                                        Object.keys( addDetails.cities ).forEach( ( element ) => {
                                            addDetails.cities[ element ][ variant ].colors[ index1 ].image = event.target.files[ 0 ];
                                            addDetails.cities[ element ][ variant ].colors[ index1 ].imageName = event.target.files[ 0 ].name;
                                        } )
                                        setAddDetails( { ...addDetails } );
                                    }
                                }} />
                                <Button onClick={event => {
                                    setEditFileList( color.imageNameArray?.map( ( colorImage ) => ( {
                                        name: colorImage,
                                        url: ( "https://ik.imagekit.io/kdllkkkhz/v0/b/emi-calculator-fbc7a.appspot.com/o/Make%2F" + addDetails.make + "%2FModels%2F" + addDetails.model + "%2FCities%2FChennai%2FVariants%2F" + variant + "%2FColors%2F" + color.name + "%2F" + colorImage + "?alt=media&token=178c1203-094c-4f52-b4ff-febb67b8f3d1" ).replaceAll( " ", "%20" ).replaceAll( "+", "%2B" ).replaceAll( "&amp;", "%26" ),
                                    } ) ) )
                                    editModalObject.variant = variant;
                                    editModalObject.color = index1;
                                    setEditModalObject( { ...editModalObject } );
                                    setEditImagesModal( true )
                                }}>
                                    Edit Images
                                </Button>
                                <Button onClick={event => {
                                    setSpecifications( { ...addDetails.cities[ "Mumbai" ][ variant ].specifications ?? {} } );
                                    let object = {
                                        variant: variant,
                                        type: "Edit"
                                    }
                                    setSpecificationsDetails( { ...object } )
                                    setPetrolModal( true );
                                }}>Edit Specifications</Button>
                                <Button danger onClick={event => {
                                    Object.keys( addDetails.cities ).forEach( ( element ) => {
                                        addDetails.cities[ element ][ variant ].colors.splice( index1, 1 );
                                    } )
                                    setAddDetails( { ...addDetails } );
                                }}>Delete Color</Button>
                                <Button danger onClick={event => {
                                    Object.keys( addDetails.cities ).forEach( ( element ) => {
                                        delete addDetails.cities[ element ][ variant ];
                                    } )
                                    variantArray.splice( index, 1 );
                                    setVariantArray( [ ...variantArray ] );
                                    setAddDetails( { ...addDetails } );
                                }}>Delete Variant</Button>
                            </div>
                        ) )
                    ) )}
            </div>
        },
    ];

    const kolkata = [
        {
            key: '1',
            label: <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span>Kolkata</span>
                {variantArray.length > 1 && <Button style={{ color: "black", fontWeight: "600" }} onClick={event => addModel( event )}>Add Model</Button>}
            </div>,
            children: <div className='all-make-entries-container' style={{ width: "100%", maxWidth: '100%', maxHeight: '500px', backgroundColor: "black" }}>
                {
                    Object.keys( addDetails.cities && addDetails.cities[ "Kolkata" ] ? addDetails.cities[ "Kolkata" ] : {} ).map( ( variant, index ) => (
                        addDetails.cities[ "Kolkata" ][ variant ].colors.map( ( color, index1 ) => (
                            <div className='all-make-entry-container'>
                                <input type="text" value={"Kolkata"} readOnly />
                                <input type="text" value={addDetails.make} readOnly />
                                <input type="text" value={addDetails.model} readOnly />
                                <input type="text" value={variant} readOnly />
                                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={addDetails.cities[ "Kolkata" ][ variant ].accessories_array}
                                    options={standardAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        addDetails.cities[ "Kolkata" ][ variant ].accessories_array = newValue;
                                        setAddDetails( { ...addDetails } );
                                    }}
                                    placeholder='Select Standard Accessories'
                                    maxTagCount='responsive'
                                />
                                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={addDetails.cities[ "Kolkata" ][ variant ].additional_array}
                                    options={additionalAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        addDetails.cities[ "Kolkata" ][ variant ].additional_array = newValue;
                                        setAddDetails( { ...addDetails } );
                                    }}
                                    placeholder='Select Additional Accessories'
                                    maxTagCount='responsive'
                                />
                                <input type="text" value={color.name} readOnly />
                                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    style={{
                                        width: '150px',
                                    }}
                                    value={color.status}
                                    options={[
                                        { label: "Active", value: "Active" },
                                        { label: "Out Of Stock", value: "Out Of Stock" },
                                        { label: "Discontinued", value: "Discontinued" },
                                    ]}
                                    onChange={( newValue ) => {
                                        Object.keys( addDetails.cities ).forEach( ( element ) => {
                                            addDetails.cities[ element ][ variant ].colors[ index1 ].status = newValue;
                                        } )
                                        setAddDetails( { ...addDetails } );
                                    }}
                                    placeholder='Select Status'
                                />
                                <ColorPicker showText format='hex' value={color.color ?? ""} onChange={event => {
                                    Object.keys( addDetails.cities ).forEach( ( element ) => {
                                        addDetails.cities[ element ][ variant ].colors[ index1 ].color = event.toHexString();
                                    } )
                                    setAddDetails( { ...addDetails } );
                                }} />
                                <input type="text" value={color.price} onChange={event => {
                                    if ( event.target.value !== "" ) {
                                        addDetails.cities[ "Kolkata" ][ variant ].colors[ index1 ].price = event.target.value;
                                        setAddDetails( { ...addDetails } );
                                    } else {
                                        error7();
                                    }
                                }} />
                                <Button onClick={event => document.querySelector( ".edit-make-image-upload-"+ variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" ) ).click()}>{color.imageName}</Button>
                                <input type="file" className={"edit-make-image-upload-"+ variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" )} style={{ display: "none" }} onChange={event => {
                                    if ( event.target.files[ 0 ] ) {
                                        Object.keys( addDetails.cities ).forEach( ( element ) => {
                                            addDetails.cities[ element ][ variant ].colors[ index1 ].image = event.target.files[ 0 ];
                                            addDetails.cities[ element ][ variant ].colors[ index1 ].imageName = event.target.files[ 0 ].name;
                                        } )
                                        setAddDetails( { ...addDetails } );
                                    }
                                }} />
                                <Button onClick={event => {
                                    setEditFileList( color.imageNameArray?.map( ( colorImage ) => ( {
                                        name: colorImage,
                                        url: ( "https://ik.imagekit.io/kdllkkkhz/v0/b/emi-calculator-fbc7a.appspot.com/o/Make%2F" + addDetails.make + "%2FModels%2F" + addDetails.model + "%2FCities%2FKolkata%2FVariants%2F" + variant + "%2FColors%2F" + color.name + "%2F" + colorImage + "?alt=media&token=178c1203-094c-4f52-b4ff-febb67b8f3d1" ).replaceAll( " ", "%20" ).replaceAll( "+", "%2B" ).replaceAll( "&amp;", "%26" ),
                                    } ) ) )
                                    editModalObject.variant = variant;
                                    editModalObject.color = index1;
                                    setEditModalObject( { ...editModalObject } );
                                    setEditImagesModal( true )
                                }}>
                                    Edit Images
                                </Button>
                                <Button onClick={event => {
                                    setSpecifications( { ...addDetails.cities[ "Mumbai" ][ variant ].specifications ?? {} } );
                                    let object = {
                                        variant: variant,
                                        type: "Edit"
                                    }
                                    setSpecificationsDetails( { ...object } )
                                    setPetrolModal( true );
                                }}>Edit Specifications</Button>
                                <Button danger onClick={event => {
                                    Object.keys( addDetails.cities ).forEach( ( element ) => {
                                        addDetails.cities[ element ][ variant ].colors.splice( index1, 1 );
                                    } )
                                    setAddDetails( { ...addDetails } );
                                }}>Delete Color</Button>
                                <Button danger onClick={event => {
                                    Object.keys( addDetails.cities ).forEach( ( element ) => {
                                        delete addDetails.cities[ element ][ variant ];
                                    } )
                                    variantArray.splice( index, 1 );
                                    setVariantArray( [ ...variantArray ] );
                                    setAddDetails( { ...addDetails } );
                                }}>Delete Variant</Button>
                            </div>
                        ) )
                    ) )}
            </div>
        },
    ];


    async function getInfo () {
        const querySnapshot1 = await getDocs( collection( firestore, "Make" ) );
        let companyRawArray = [];

        querySnapshot1.forEach( ( element1 ) => {
            companyRawArray.push( element1.id );
        } )
        setCompanyArray( [ ...companyRawArray ] );

        const querySnapshot2 = await getDocs( query( collection( firestore, "Accessories" ), where( "category", "==", "standard" ) ) );
        const querySnapshot21 = await getDocs( query( collection( firestore, "Accessories" ), where( "category", "==", "additional" ) ) );
        let StandardAccessoriesRawArray = [];
        let AdditionalAccessoriesRawArray = [];

        querySnapshot2.forEach( ( element ) => {
            StandardAccessoriesRawArray.push( element.data().name );
        } )
        querySnapshot21.forEach( ( element ) => {
            AdditionalAccessoriesRawArray.push( element.data().name );
        } )
        setStandardAccessories( [ ...StandardAccessoriesRawArray ] );
        setStandardAccessoryArray( [ ...StandardAccessoriesRawArray ] );
        setAdditionalAccessories( [ ...AdditionalAccessoriesRawArray ] );
        setAdditionalAccessoryArray( [ ...AdditionalAccessoriesRawArray ] );

        onSnapshot( collection( firestore, "banks" ), ( snapshot ) => {
            let schemesArray = [];
            snapshot.forEach( async ( element ) => {
                onSnapshot( collection( firestore, "banks/" + element.id + "/schemes" ), ( snapshot ) => {
                    snapshot.forEach( ( element1 ) => {
                        schemesArray.push( {
                            id: element1.id,
                            data: element1.data(),
                            scheme: element1.data().scheme,
                            bank: element.data().name,
                            bankId: element.id
                        } )
                    } );
                    setSchemes( [ ...schemesArray ] );
                    document.querySelector( ".sending-loader" ).style.display = "none";
                } )
            } )
        } );


        // const url = 'https://verifyaadhaarnumber.p.rapidapi.com/Uidverifywebsvcv1/VerifyAadhaarNumber';
        // const options = {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'application/x-www-form-urlencoded',
        //         'X-RapidAPI-Key': 'd8dd6cf6a7msha9a35b77edc055ep190fd9jsnbea0221c9988',
        //         'X-RapidAPI-Host': 'verifyaadhaarnumber.p.rapidapi.com'
        //     },
        //     body: new URLSearchParams( {
        //         txn_id: '17c6fa41-778f-49c1-a80a-cfaf7fae2fb8',
        //         consent: 'Y',
        //         uidnumber: '399637488378',
        //         clientid: '222',
        //         method: 'uidvalidatev2'
        //     } )
        // };

        // try {
        //     const response = await fetch( url, options );
        //     const result = await response.json();
        //     console.log( result );
        // } catch ( error ) {
        //     console.error( error );
        // }


        // const url = 'https://pan-card-verification-at-lowest-price.p.rapidapi.com/verifyPan/FDLPB4000C';
        // const options = {
        //     method: 'GET',
        //     headers: {
        //         'x-rapid-api': 'rapid-api-database',
        //         'X-RapidAPI-Key': 'd8dd6cf6a7msha9a35b77edc055ep190fd9jsnbea0221c9988',
        //         'X-RapidAPI-Host': 'pan-card-verification-at-lowest-price.p.rapidapi.com'
        //     }
        // };

        // try {
        //     const response = await fetch( url, options );
        //     const result = await response.json();
        //     console.log( result );
        // } catch ( error ) {
        //     console.error( error );
        // }
    }

    async function addModel ( event ) {
        event.preventDefault();
        document.querySelector( ".sending-loader" ).style.display = "flex";
        await setDoc( doc( firestore, "Make/" + addDetails.make + "/Models/", addDetails.model ), {
            "vehicle_type": vehicleType,
            "two_wheeler_type": addDetails.twoWheelerType,
            "image": addDetails.modelImageName,
            "thrust": addDetails.thrust ?? "",
            "link": addDetails.modelLink ? addDetails.modelLink : "",
            "youtube_link": addDetails.modelYoutubeLink ? addDetails.modelYoutubeLink : "",
            "insurance_discount": parseFloat( addDetails.insuranceDiscount ).toFixed( 2 ),
            "payout_percentage": parseFloat( addDetails.payoutPercentage ).toFixed( 2 ),
            "payout_on": addDetails.payoutOn,
            "timestamp": new Date(),
            schemes: selectedSchemes?.map( ( element ) => ( {
                id: element.id
            } ) )
        } );

        selectedSchemes?.forEach( async ( element ) => {
            let object = element;
            let array = new Set();
            object.data.models ?? [].forEach( ( element1 ) => {
                array.add( JSON.stringify( element1 ) );
            } );
            array.add( JSON.stringify( {
                make: addDetails.make,
                model: addDetails.model
            } ) )
            await updateDoc( doc( firestore, "banks/" + object.bankId + "/schemes", object.id ), {
                models: Array.from( array ).map( ( element1 ) => JSON.parse( element1 ) )
            } )
        } )

        const storageRef1 = ref( storage, "Make/" + addDetails.make + "/Models/" + addDetails.model + "/" + addDetails.modelImageName );
        await uploadBytes( storageRef1, addDetails.modelImage ).then( ( snapshot ) => {
            console.log( 'Uploaded a blob or file!' );
        } );

        Object.keys( addDetails.cities ? addDetails.cities : {} ).forEach( async ( element ) => {
            await setDoc( doc( firestore, "Make/" + addDetails.make + "/Models/" + addDetails.model + "/Cities/", element ), {
                "state": element
            } );
            Object.keys( addDetails.cities[ element ] ? addDetails.cities[ element ] : {} ).forEach( async ( element1 ) => {
                await setDoc( doc( firestore, "Make/" + addDetails.make + "/Models/" + addDetails.model + "/Cities/" + element + "/Variants/", element1 ), {
                    "model": addDetails.model,
                    "accessories_array": addDetails.cities[ element ][ element1 ].accessories_array,
                    "additional_array": addDetails.cities[ element ][ element1 ].additional_array,
                    "specifications": JSON.stringify( addDetails.cities[ element ][ element1 ].specifications ),
                } );
                addDetails.cities[ element ][ element1 ].colors.forEach( async ( element2 ) => {
                    await setDoc( doc( firestore, "Make/" + addDetails.make + "/Models/" + addDetails.model + "/Cities/" + element + "/Variants/" + element1 + "/Colors/", element2.name ), {
                        color: element2.color ?? "",
                        "price": parseInt( element2.price ),
                        "image": element2.imageName,
                        imageArray: element2.imageNameArray,
                        status: element2.status
                    } );

                    await setDoc( doc( firestore, "aapli_global/aapli_search/search_items", addDetails.make + " " + addDetails.model + " " + element1 + " " + element2.name ), {
                        name: addDetails.make + " " + addDetails.model + " " + element1 + " " + element2.name,
                        path: "/" + addDetails.make + "/" + addDetails.model + "/" + element1 + "/" + element2.name
                    } )

                    const storageRef = ref( storage, "Make/" + addDetails.make + "/Models/" + addDetails.model + "/Cities/" + element + "/Variants/" + element1 + "/Colors/" + element2.imageName );
                    await uploadBytes( storageRef, element2.image ).then( ( snapshot ) => {
                        console.log( 'Uploaded a blob or file!' );
                    } );

                    if ( element2.imageArray?.length !== 0 ) {
                        element2.imageArray?.forEach( async ( image ) => {
                            if ( image ) {
                                const storageRef = ref( storage, "Make/" + addDetails.make + "/Models/" + addDetails.model + "/Cities/" + element + "/Variants/" + element1 + "/Colors/" + element2.name + "/" + image.name );
                                await uploadBytes( storageRef, image ).then( ( snapshot ) => {
                                    console.log( 'Uploaded a blob or file!' );
                                } );
                            }
                        } )
                    }
                } )
            } )
        } )

        setAddDetails( {} );
        setVariantArray( [] );
        setStandardAccessories( setStandardAccessoryArray );
        setAdditionalAccessories( setAdditionalAccessoryArray );
        setColorObject( {
            variant: "All",
            colorName: "",
            colorPrice: 0
        } )
        setVariantName( "" );
        setVehicleType( "" );
        setSpecifications( {} );
        document.querySelector( ".sending-loader" ).style.display = "none";
    }

    const getBase64 = ( file ) =>
        new Promise( ( resolve, reject ) => {
            const reader = new FileReader();
            reader.readAsDataURL( file );
            reader.onload = () => resolve( reader.result );
            reader.onerror = ( error ) => reject( error );
        } );

    const handleCancel = () => setPreviewOpen( false );
    const handlePreview = async ( file ) => {
        if ( !file.url && !file.preview ) {
            file.preview = await getBase64( file.originFileObj );
        }
        setPreviewImage( file.url || file.preview );
        setPreviewOpen( true );
        setPreviewTitle( file.name || file.url.substring( file.url.lastIndexOf( '/' ) + 1 ) );
    };
    const handleChange = ( { fileList: newFileList } ) => setFileList( newFileList );
    const handleEditChange = ( { fileList: newFileList } ) => {
        Object.keys( addDetails.cities ).forEach( ( element ) => {
            addDetails.cities[ element ][ editModalObject.variant ].colors[ editModalObject.color ].imageArray = newFileList.map( ( element ) => element.originFileObj );
            addDetails.cities[ element ][ editModalObject.variant ].colors[ editModalObject.color ].imageNameArray = newFileList.map( ( element ) => element.name );
        } )
        setEditFileList( newFileList );
        setAddDetails( { ...addDetails } );
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const error = () => {
        messageApi.open( {
            type: 'error',
            content: 'Select Company to Proceed',
        } );
    };

    const error1 = () => {
        messageApi.open( {
            type: 'error',
            content: 'You need to enter a name for the variant to add.',
        } );
    };

    const error2 = () => {
        messageApi.open( {
            type: 'error',
            content: 'Select a vehicle type before proceeding.',
        } );
    };

    const error3 = () => {
        messageApi.open( {
            type: 'error',
            content: 'Select a two wheeler type before proceeding.',
        } );
    };

    const error4 = () => {
        messageApi.open( {
            type: 'error',
            content: 'Select Payout On Value before proceeding.',
        } );
    };

    const error5 = () => {
        messageApi.open( {
            type: 'error',
            content: 'You need to upload a model image to proceed.',
        } );
    };

    const error6 = () => {
        messageApi.open( {
            type: 'error',
            content: 'You need to upload a color image to proceed.',
        } );
    };

    const error7 = () => {
        messageApi.open( {
            type: 'error',
            content: "The price can't be zero. You need to delete the color.",
        } );
    };

    const error8 = () => {
        messageApi.open( {
            type: 'error',
            content: "Insurance Discount should not be more than 100.",
        } );
    };

    const error9 = () => {
        messageApi.open( {
            type: 'error',
            content: "Payout Percentage should not be more than 100.",
        } );
    };

    const element = () => {
        return <Button style={{ color: "black", fontWeight: "600" }}>Show Charges</Button>
    }

    const element1 = () => {
        return <Button style={{ color: "black", fontWeight: "600", fontSize: "14px" }}>Show Prices</Button>
    }

    useEffect( () => {
        document.querySelector( ".sending-loader" ).style.display = "flex";
        document.querySelectorAll( ".ant-menu-item" )[ 0 ].click();
        onAuthStateChanged( auth, ( user ) => {
            if ( user ) {
                getInfo();
            } else {
                window.location.href = "/login";
            }
        } );
    }, [] );

    return (
        <div className='making-container'>
            {contextHolder}
            <Modal
                title="Specification Details"
                open={petrolModal}
                onOk={event => {
                    setConfirmLoading( true );
                    if ( specificationsDetails.type === "Add" ) {
                        Object.keys( addDetails.cities ).forEach( ( element ) => {
                            addDetails.cities[ element ][ variantName ] = {
                                specifications: specifications,
                                accessories_array: standardAccessories,
                                additional_array: additionalAccessories,
                                colors: []
                            }
                        } )
                        console.log( addDetails );
                    } else if ( specificationsDetails.type === "Edit" ) {
                        Object.keys( addDetails.cities ).forEach( ( element ) => {
                            addDetails.cities[ element ][ specificationsDetails.variant ].specifications = specifications;
                        } )
                    }
                    setAddDetails( { ...addDetails } );
                    setSpecificationsDetails( {} );
                    setSpecifications( {} );
                    setPetrolModal( false )
                    setConfirmLoading( false );
                }}
                confirmLoading={confirmLoading}
                onCancel={event => {
                    setSpecificationsDetails( {} );
                    setSpecifications( {} );
                    setPetrolModal( false )
                }}
                centered
            >
                <div className='specifications-modal-container'>
                    <input type="text" placeholder="Enter Mileage" value={specifications.mileage ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setSpecifications( { ...specifications, mileage: event.target.value } );
                        }
                    }} />
                    <input type="text" placeholder="Enter Kerb Weight" value={specifications.kerb_weight ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setSpecifications( { ...specifications, kerb_weight: event.target.value } );
                        }
                    }} />
                    <input type="text" placeholder="Enter Seat Height" value={specifications.seat_height ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setSpecifications( { ...specifications, seat_height: event.target.value } );
                        }
                    }} />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select Front Brake"
                        allowClear
                        value={specifications.front_brake ?? []}
                        options={[
                            { value: 'Drum', label: 'Drum' },
                            { value: 'Disc', label: 'Disc' },
                            { value: 'ABS', label: 'ABS' },
                        ]}
                        onChange={event => {
                            setSpecifications( { ...specifications, front_brake: event } );
                        }}
                        style={{
                            width: "100%",
                            textAlign: "left"
                        }}
                    />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select Rear Brake"
                        allowClear
                        value={specifications.rear_brake ?? []}
                        options={[
                            { value: 'Drum', label: 'Drum' },
                            { value: 'Disc', label: 'Disc' },
                            { value: 'ABS', label: 'ABS' },
                        ]}
                        onChange={event => {
                            setSpecifications( { ...specifications, rear_brake: event } );
                        }}
                        style={{
                            width: "100%",
                            textAlign: "left"
                        }}
                    />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select Brake Type"
                        allowClear
                        value={specifications.brake_type ?? []}
                        options={[
                            { value: 'Drum', label: 'Drum' },
                            { value: 'Disc', label: 'Disc' },
                            { value: 'Single Channel ABS', label: 'Single Channel ABS' },
                            { value: 'Dual Channel ABS', label: 'Dual Channel ABS' },
                        ]}
                        onChange={event => {
                            setSpecifications( { ...specifications, brake_type: event } );
                        }}
                        style={{
                            width: "100%",
                            textAlign: "left"
                        }}
                    />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select Class"
                        allowClear
                        value={specifications.class ?? []}
                        options={[
                            { value: 'Adventure', label: 'Adventure' },
                            { value: 'Sports', label: 'Sports' },
                            { value: 'Cruiser', label: 'Cruiser' },
                            { value: 'Daily Commuter', label: 'Daily Commuter' },
                        ]}
                        onChange={event => {
                            setSpecifications( { ...specifications, class: event } );
                        }}
                        style={{
                            width: "100%",
                            textAlign: "left"
                        }}
                    />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select Connectivity"
                        allowClear
                        value={specifications.connectivity ?? []}
                        options={[
                            { value: 'Bluetooth', label: 'Bluetooth' },
                            { value: 'GPS', label: 'GPS' },
                            { value: 'NFS', label: 'NFS' },
                        ]}
                        onChange={event => {
                            setSpecifications( { ...specifications, connectivity: event } );
                        }}
                        style={{
                            width: "100%",
                            textAlign: "left"
                        }}
                        mode='multiple'
                        maxTagCount="responsive"
                    />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select Start"
                        allowClear
                        value={specifications.start ?? []}
                        options={[
                            { value: 'Standard Key', label: 'Standard Key' },
                            { value: 'Keyless', label: 'Keyless' },
                        ]}
                        onChange={event => {
                            setSpecifications( { ...specifications, start: event } );
                        }}
                        style={{
                            width: "100%",
                            textAlign: "left"
                        }}
                    />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select Wheel"
                        allowClear
                        value={specifications.wheel ?? []}
                        options={[
                            { value: 'Sheet Metal', label: 'Sheet Metal' },
                            { value: 'Alloy', label: 'Alloy' },
                        ]}
                        onChange={event => {
                            setSpecifications( { ...specifications, wheel: event } );
                        }}
                        style={{
                            width: "100%",
                            textAlign: "left"
                        }}
                    />
                    <input type="text" placeholder="Enter Fuel Capacity" value={specifications.fuel_capacity ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setSpecifications( { ...specifications, fuel_capacity: event.target.value } );
                        }
                    }} />
                    <input type="text" placeholder="Enter Vehicle Length" value={specifications.vehicle_length ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setSpecifications( { ...specifications, vehicle_length: event.target.value } );
                        }
                    }} />
                    <JoditEditor
                        value={specifications.productSummary ?? ""}
                        tabIndex={1}
                        onBlur={newContent => setSpecifications( { ...specifications, productSummary: newContent } )}
                        onChange={newContent => setSpecifications( { ...specifications, productSummary: newContent } )}
                    />
                </div>
            </Modal>
            <Modal
                title="Add Images"
                open={imagesModal}
                // onOk={event => addModel( event )}
                onCancel={event => setImagesModal( false )}
                okButtonProps={{
                    style: {
                        display: "none"
                    }
                }}
            >
                <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-circle"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                >
                    {uploadButton}
                </Upload>
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
            </Modal>
            <Modal
                title="Edit Images"
                open={editImagesModal}
                // onOk={event => addModel( event )}
                onCancel={event => setEditImagesModal( false )}
                okButtonProps={{
                    style: {
                        display: "none"
                    }
                }}
            >
                <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    headers={{
                        mode: "no-cors"
                    }}
                    listType="picture-circle"
                    fileList={editFileList}
                    onPreview={handlePreview}
                    onChange={handleEditChange}
                >
                    {uploadButton}
                </Upload>
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
            </Modal>
            <form className='making-container-top-container' onSubmit={event => {
                event.preventDefault();
                if ( colorObject.colorImage && colorObject.colorImageName && addDetails.modelImage && addDetails.modelImageName ) {
                    if ( colorObject.variant !== "All" ) {
                        let object = addDetails;
                        Object.keys( object.cities ).forEach( ( element ) => {
                            let array = object.cities[ element ][ colorObject.variant ].colors;
                            array.push( {
                                name: colorObject.colorName,
                                color: colorObject.colorHex,
                                price: colorObject.colorPrice,
                                image: colorObject.colorImage,
                                imageName: colorObject.colorImageName,
                                imageArray: fileList.map( ( element ) => element.originFileObj ),
                                imageNameArray: fileList.map( ( element ) => element.name ),
                                status: "Active"
                            } )
                            object.cities[ element ][ colorObject.variant ].colors = array;
                        } )
                        setColorObject( {} );
                        setAddDetails( { ...object } );
                    } else {
                        let object = addDetails;
                        Object.keys( object.cities ).forEach( ( element ) => {
                            Object.keys( object.cities[ element ] ).forEach( ( element1 ) => {
                                let array = object.cities[ element ][ element1 ].colors;
                                array.push( {
                                    name: colorObject.colorName,
                                    color: colorObject.colorHex,
                                    price: colorObject.colorPrice,
                                    image: colorObject.colorImage,
                                    imageName: colorObject.colorImageName,
                                    imageArray: fileList.map( ( element ) => element.originFileObj ),
                                    imageNameArray: fileList.map( ( element ) => element.name ),
                                    status: "Active"
                                } )
                                object.cities[ element ][ element1 ].colors = array;
                            } )
                        } )
                        setFileList( [] );
                        setColorObject( {
                            variant: colorObject.variant,
                            colorName: "",
                            colorPrice: "",
                            colorHex: ""
                        } );
                        setAddDetails( { ...object } );
                    }
                } else if ( !vehicleType || vehicleType === "" ) {
                    error2();
                } else if ( !addDetails.twoWheelerType || addDetails.twoWheelerType === "" ) {
                    error3();
                } else if ( !addDetails.payoutOn || addDetails.payoutOn === "" ) {
                    error4();
                } else if ( !addDetails.modelImage || !addDetails.modelImageName ) {
                    error5();
                } else if ( !colorObject.colorImage || !colorObject.colorImageName ) {
                    error6();
                }
            }}>
                <div>
                    {/* <Button onClick={async event => {
                        companyArray.forEach( async ( make ) => {
                            let modelDocs = await getDocs( collection( firestore, "Make/" + make + "/Models/" ) );
                            modelDocs.forEach( async ( model ) => {
                                let variantDocs = await getDocs( collection( firestore, "Make/" + make + "/Models/" + model.id + "/Cities/Mumbai/Variants/" ) );
                                variantDocs.forEach( async ( element ) => {
                                    let colorDocs = await getDocs( collection( firestore, "Make/" + make + "/Models/" + model.id + "/Cities/Mumbai/Variants/" + element.id + "/Colors" ) );
                                    colorDocs.forEach( async ( color ) => {
                                        await setDoc( doc( firestore, "aapli_global/aapli_search/search_items", make + " " + model.id + " " + element.id + " " + color.id ), {
                                            name: make + " " + model.id + " " + element.id + " " + color.id,
                                            path: "/" + make + "/" + model.id + "/" + element.id + "/" + color.id
                                        } )
                                    } )
                                } )
                            } )
                        } )
                    }}>Save All Models</Button> */}
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        allowClear
                        value={addDetails.make ? addDetails.make : "Select Company"}
                        onClear={event => setAddDetails( {} )}
                        placeholder="Select a Company"
                        style={{ width: "180px" }}
                        onChange={async event => {
                            document.querySelector( ".sending-loader" ).style.display = "flex";
                            if ( event ) {
                                const querySnapshot = await getDocs( collection( firestore, "States" ) );
                                let citiesArray = [];
                                let object = {
                                    make: event,
                                    cities: {}
                                }
                                querySnapshot.forEach( async ( element ) => {
                                    const querySnapshot = await getDoc( doc( firestore, "States", element.id ) );
                                    if ( querySnapshot.data().Cities !== undefined ) {
                                        querySnapshot.data().Cities.forEach( ( element1 ) => {
                                            citiesArray.push( element1 );
                                            object.cities[ element1 ] = {};
                                        } )
                                        setAddDetails( { ...object } );
                                    } else {
                                        alert( "No City Exist in the following State." )
                                    }
                                } )
                            }
                            document.querySelector( ".sending-loader" ).style.display = "none";
                        }}
                        options={companyArray.map( ( element ) => ( { label: element, value: element } ) )}
                    />
                    <input style={{ width: "180px" }} type="text" placeholder='Model Name' className='model_name' value={addDetails.model ?? ""} onChange={event => {
                        if ( addDetails.make && addDetails.make !== "" ) {
                            setAddDetails( { ...addDetails, model: event.target.value } );
                        } else {
                            event.target.value = "";
                            error();
                        }
                    }} required />
                    <input style={{ width: "180px" }} type="text" placeholder='Model Link' className='model_link' value={addDetails.modelLink ?? ""} onChange={event => {
                        if ( addDetails.make && addDetails.make !== "" ) {
                            setAddDetails( { ...addDetails, modelLink: event.target.value } );
                        } else {
                            event.target.value = "";
                            error();
                        }
                    }} />
                    <input style={{ width: "180px" }} type="text" placeholder='Youtube Link' className='model_youtube_link' value={addDetails.modelYoutubeLink ?? ""} onChange={event => {
                        if ( addDetails.make && addDetails.make !== "" ) {
                            setAddDetails( { ...addDetails, modelYoutubeLink: event.target.value } );
                        } else {
                            event.target.value = "";
                            error();
                        }
                    }} />
                    <input style={{ width: "80px" }} type="text" placeholder="Enter Thrust" value={addDetails.thrust ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setAddDetails( { ...addDetails, thrust: event.target.value } );
                        }
                    }} />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        allowClear
                        value={vehicleType ?? []}
                        placeholder="Vehicle Type"
                        style={{ width: "100px" }}
                        onChange={event => {
                            if ( event ) {
                                if ( addDetails.make && addDetails.make !== "" ) {
                                    setVehicleType( event );
                                } else {
                                    setVehicleType( "" );
                                    error();
                                }
                            } else {
                                setVehicleType( "" );
                            }
                        }}
                        options={[
                            { label: "Petrol", value: "Petrol" },
                            { label: "Electric", value: "Electric" }
                        ]}
                    />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        allowClear
                        value={addDetails.twoWheelerType ?? []}
                        placeholder="Two Wheeler type"
                        style={{ width: "100px" }}
                        onChange={event => {
                            if ( addDetails.make && addDetails.make !== "" ) {
                                setAddDetails( { ...addDetails, twoWheelerType: event } );
                            } else {
                                setAddDetails( { ...addDetails, twoWheelerType: "" } );
                                error();
                            }
                        }}
                        options={[
                            { label: "Scooter", value: "Scooter" },
                            { label: "Motor Cycle", value: "Motorcycle" },
                            { label: "Moped", value: "Moped" },
                        ]}
                    />
                    <input type="text" style={{ width: '100px' }} placeholder='Insurance Discount (%)' className='insurance_discount' value={addDetails.insuranceDiscount ?? ""} onChange={event => {
                        if ( addDetails.make && addDetails.make !== "" ) {
                            if ( !isNaN( event.target.value ) ) {
                                if ( parseFloat( event.target.value ) < 100 || event.target.value === "" ) {
                                    setAddDetails( { ...addDetails, insuranceDiscount: event.target.value } );
                                } else {
                                    error8();
                                }
                            } else {
                                event.target.value = ""
                            }
                        } else {
                            event.target.value = "";
                            error();
                        }
                    }} required />
                    <input type="text" style={{ width: "100px" }} placeholder='Payout Percentage (%)' className='payout_percentage' value={addDetails.payoutPercentage ?? ""} onChange={event => {
                        if ( addDetails.make && addDetails.make !== "" ) {
                            if ( !isNaN( event.target.value ) ) {
                                if ( parseFloat( event.target.value ) < 100 || event.target.value === "" ) {
                                    setAddDetails( { ...addDetails, payoutPercentage: event.target.value } );
                                } else {
                                    error9();
                                }
                            } else {
                                event.target.value = ""
                            }
                        } else {
                            event.target.value = "";
                            error();
                        }
                    }} required />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        allowClear
                        value={addDetails.payoutOn ?? []}
                        placeholder="Payout On"
                        style={{ width: "100px" }}
                        onChange={event => {
                            if ( addDetails.make && addDetails.make !== "" ) {
                                setAddDetails( { ...addDetails, payoutOn: event } );
                            } else {
                                setAddDetails( { ...addDetails, payoutOn: "" } );
                                error();
                            }
                        }}
                        options={[
                            { label: "OD", value: "OD" },
                            { label: "Net", value: "Net" }
                        ]}
                    />
                    <Button onClick={event => {
                        if ( addDetails.make && addDetails.make !== "" ) {
                            document.getElementById( "model_image" ).click()
                        } else {
                            error();
                        }
                    }} className="upload_image_button">
                        {addDetails.modelImage && addDetails.modelImageName ? "Uploaded" : "Upload Model Image"}
                    </Button>
                    <input type="file" placeholder='Image' id='model_image' accept="image/png, image/jpeg" onChange={event => {
                        if ( event.target.files[ 0 ] ) {
                            addDetails.modelImage = event.target.files[ 0 ];
                            addDetails.modelImageName = event.target.files[ 0 ]?.name;
                            setAddDetails( { ...addDetails } );
                        }
                    }} style={{ display: "none" }} />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        allowClear
                        mode='multiple'
                        style={{
                            width: '150px',
                        }}
                        value={standardAccessories}
                        options={standardAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                        onChange={( newValue ) => {
                            setStandardAccessories( newValue );
                        }}
                        placeholder='Select Standard Accessories'
                        maxTagCount='responsive'
                    />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        allowClear
                        mode='multiple'
                        style={{
                            width: '150px',
                        }}
                        value={additionalAccessories}
                        options={additionalAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                        onChange={( newValue ) => {
                            setAdditionalAccessories( newValue );
                        }}
                        placeholder='Select Additional Accessories'
                        maxTagCount='responsive'
                    />
                    <input style={{ width: "100px", height: "max-content" }} value={variantName} type="text" placeholder='Variant Name' className='variant_name' onChange={event => {
                        if ( addDetails.make && addDetails.make !== "" ) {
                            setVariantName( event.target.value );
                        } else {
                            setVariantName( "" );
                            error();
                        }
                    }} />
                    <Button onClick={event => {
                        if ( variantName !== "" ) {
                            let object = {
                                type: "Add"
                            }
                            setSpecificationsDetails( { ...object } );
                            setPetrolModal( true );
                        } else {
                            error1();
                        }
                    }}>Add Specifications</Button>
                    <Button onClick={event => {
                        if ( variantName !== "" ) {
                            let object = addDetails;
                            Object.keys( object.cities ).forEach( ( element ) => {
                                object.cities[ element ][ variantName ] = {
                                    specifications: object.cities[ element ][ variantName ].specifications ?? {},
                                    accessories_array: standardAccessories,
                                    additional_array: additionalAccessories,
                                    colors: []
                                }
                            } )
                            setAddDetails( { ...object } );
                            setVariantArray( current => [ ...current, variantName ] );
                            setVariantName( "" );
                        } else {
                            error1();
                        }
                    }}>Add</Button>
                </div>
                {
                    variantArray.length > 1 && <div>
                        <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                            allowClear
                            value={colorObject.variant}
                            placeholder="Select Variant"
                            style={{ width: "100px" }}
                            onChange={event => {
                                if ( event ) {
                                    setColorObject( { ...colorObject, variant: event } );
                                } else {
                                    setColorObject( { ...colorObject, variant: "All" } );
                                }
                            }}
                            options={variantArray.map( ( element ) => ( { label: element, value: element } ) )}
                        />
                        <input style={{ width: "150px" }} type="text" placeholder='Color Name' className='variant_color_name' value={colorObject.colorName ?? ""} onChange={event => setColorObject( { ...colorObject, colorName: event.target.value } )} required />
                        <ColorPicker showText format='hex' value={colorObject.colorHex ?? ""} onChange={event => {
                            setColorObject( { ...colorObject, colorHex: event.toHexString() } )
                        }} />
                        <input style={{ width: "100px" }} type="text" placeholder='Price' className='variant_price' value={colorObject.colorPrice ?? ""} onChange={event => {
                            if ( !isNaN( event.target.value ) ) {
                                setColorObject( { ...colorObject, colorPrice: event.target.value } );
                            } else {
                                event.target.value = ""
                            }
                        }} required />
                        <Button onClick={event => {
                            document.getElementById( "color_image" ).click()
                        }} className="upload_variant_button">
                            {colorObject.colorImage && colorObject.colorImageName ? "Uploaded" : "Upload Color Image"}
                        </Button>
                        <input type="file" placeholder='Image' id='color_image' accept="image/png, image/jpeg" onChange={event => {
                            colorObject.colorImage = event.target.files[ 0 ];
                            colorObject.colorImageName = event.target.files[ 0 ].name;
                            setColorObject( { ...colorObject } );
                        }} style={{ display: "none" }} />
                        <Button onClick={event => setImagesModal( true )}>
                            Upload Images
                        </Button>
                        <Button htmlType='submit'>Add Color</Button>
                    </div>
                }
            </form >
            <Collapse items={items} style={{
                width: "95%",
                background: "rgba(150, 150, 150, 0.37)"
            }} expandIcon={element} expandIconPosition='end' />
            {Object.keys( addDetails.cities ?? {} ).length !== 0 && <Collapse items={mumbai} style={{
                width: "95%",
                background: "rgba(150, 150, 150, 0.37)"
            }} expandIcon={element1} expandIconPosition='end' />}
            {Object.keys( addDetails.cities ?? {} ).length !== 0 && <Collapse items={newDelhi} style={{
                width: "95%",
                background: "rgba(150, 150, 150, 0.37)"
            }} expandIcon={element1} expandIconPosition='end' />}
            {Object.keys( addDetails.cities ?? {} ).length !== 0 && <Collapse items={chennai} style={{
                width: "95%",
                background: "rgba(150, 150, 150, 0.37)"
            }} expandIcon={element1} expandIconPosition='end' />}
            {Object.keys( addDetails.cities ?? {} ).length !== 0 && <Collapse items={kolkata} style={{
                width: "95%",
                background: "rgba(150, 150, 150, 0.37)"
            }} expandIcon={element1} expandIconPosition='end' />}
        </div >
    )
}

export default Make