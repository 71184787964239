import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { firestore, auth } from "../../firebase/firebaseConfig";
import { addDoc, getDoc, getDocs, doc, collection, deleteDoc, onSnapshot } from '@firebase/firestore';
import { Button, Input, Select, message } from 'antd';

function TrendingVehicles () {
    let [ addObject, setAddObject ] = useState( {} );
    let [ vehicles, setVehicles ] = useState( [] );
    let [ make, setMake ] = useState( [] );
    let [ models, setModels ] = useState( [] );
    let [ variants, setVariants ] = useState( [] );
    let [ colors, setColors ] = useState( [] );
    const [ messageApi, contextHolder ] = message.useMessage();

    async function onload () {
        let makeDocs = await getDocs( collection( firestore, "Make" ) );
        let makeArray = [];
        makeDocs.forEach( ( element ) => {
            makeArray.push( element.id );
        } )
        setMake( [ ...makeArray ] );

        onSnapshot( collection( firestore, "aapli_global/Trending Vehicles/Vehicles List" ), ( snapshot ) => {
            let vehiclesArray = [];
            snapshot.forEach( ( element ) => {
                vehiclesArray.push( {
                    id: element.id,
                    make: element.data().make,
                    model: element.data().model,
                    variant: element.data().variant,
                    color: element.data().color,
                    index: element.data().index
                } )
            } )
            setVehicles( [ ...vehiclesArray ] );
            document.querySelector( ".sending-loader" ).style.display = "none";
        } )
    }

    const error = () => {
        messageApi.open( {
            type: 'error',
            content: 'All Fields are compulsory to be filled.',
        } );
    };

    useEffect( () => {
        document.querySelector( ".sending-loader" ).style.display = "flex";
        document.querySelectorAll( ".ant-menu-item" )[ 13 ].click();
        onAuthStateChanged( auth, ( user ) => {
            if ( user ) {
                onload();
            } else {
                window.location.href = "/login";
            }
        } );
    }, [] )

    return (
        <div className='making-container'>
            {contextHolder}
            <form onSubmit={async event => {
                event.preventDefault();
                document.querySelector( ".sending-loader" ).style.display = "flex";
                if ( addObject.make && addObject.model && addObject.variant && addObject.color ) {
                    await addDoc( collection( firestore, "aapli_global/Trending Vehicles/Vehicles List" ), addObject );
                    setAddObject( {} );
                } else {
                    error();
                }
                document.querySelector( ".sending-loader" ).style.display = "none";
            }} className="making-container-top-container">
                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                    allowClear
                    value={addObject.make ?? []}
                    placeholder="Select Make"
                    style={{ width: "180px" }}
                    onChange={async event => {
                        if ( event ) {
                            let modelDocs = await getDocs( collection( firestore, "Make/" + event + "/Models" ) );
                            let modelsArray = [];
                            modelDocs.forEach( ( element ) => {
                                modelsArray.push( element.id );
                            } )
                            setModels( [ ...modelsArray ] );
                            setAddObject( { ...addObject, make: event } );
                        } else {
                            delete addObject.make;
                            delete addObject.model;
                            delete addObject.variant;
                            delete addObject.color;
                            setAddObject( { ...addObject } );
                            setModels( [] );
                            setVariants( [] );
                            setColors( [] );
                        }
                    }}
                    options={make.map( ( element ) => ( { label: element, value: element } ) )}
                />
                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                    allowClear
                    value={addObject.model ?? []}
                    placeholder="Select Model"
                    style={{ width: "180px" }}
                    onChange={async event => {
                        if ( event ) {
                            let modelDoc = await getDoc( doc( firestore, "Make/" + addObject.make + "/Models", event ) );
                            let variantsDocs = await getDocs( collection( firestore, "Make/" + addObject.make + "/Models/" + event + "/Cities/Mumbai/Variants" ) );
                            let variantsArray = [];
                            variantsDocs.forEach( ( element ) => {
                                variantsArray.push( element.id );
                            } )
                            setVariants( [ ...variantsArray ] );
                            addObject.model = event;
                            addObject.twoWheelerType = modelDoc.data().two_wheeler_type;
                            setAddObject( { ...addObject } );
                            setColors( [] );
                        } else {
                            delete addObject.model;
                            delete addObject.twoWheelerType;
                            delete addObject.variant;
                            delete addObject.color;
                            delete addObject.cc;
                            setAddObject( { ...addObject } );
                            setVariants( [] );
                            setColors( [] );
                        }
                    }}
                    options={models.map( ( element ) => ( { label: element, value: element } ) )}
                />
                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                    allowClear
                    value={addObject.variant ?? []}
                    placeholder="Select Variant"
                    style={{ width: "180px" }}
                    onChange={async event => {
                        if ( event ) {
                            let colorsDocs = await getDocs( collection( firestore, "Make/" + addObject.make + "/Models/" + addObject.model + "/Cities/Mumbai/Variants/" + event + "/Colors" ) );
                            let colorsArray = [];
                            colorsDocs.forEach( ( element ) => {
                                colorsArray.push( element.id );
                            } )
                            setAddObject( { ...addObject, variant: event } );
                            setColors( [ ...colorsArray ] );
                        } else {
                            delete addObject.variant;
                            delete addObject.color;
                            setAddObject( { ...addObject } );
                            setColors( [] );
                        }
                    }}
                    options={variants.map( ( element ) => ( { label: element, value: element } ) )}
                />
                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                    allowClear
                    value={addObject.color ?? []}
                    placeholder="Select Color"
                    style={{ width: "180px" }}
                    onChange={async event => {
                        if ( event ) {
                            setAddObject( { ...addObject, color: event } );
                        } else {
                            delete addObject.color;
                        }
                    }}
                    options={colors.map( ( element ) => ( { label: element, value: element } ) )}
                />
                <Input value={addObject.index} placeholder="Enter Index" style={{width: "180px"}} onChange={event => {
                    if ( !isNaN( event.target.value ) ) {
                        setAddObject( { ...addObject, index: event.target.value } );
                    }
                }} required></Input>
                <Button htmlType='submit'>Add Vehicle</Button>
            </form>
            <div className="all-make-city-container" style={{ width: "95%" }}>
                Vehicles
            </div>
            <div className='all-make-entries-container'>
                {vehicles.map( ( element, index ) => (
                    <div style={{ alignItems: "center", width: "100%", justifyContent: "space-between" }} className='all-make-entry-container'>
                        <Input value={element.make} readOnly></Input>
                        <Input value={element.model} readOnly></Input>
                        <Input value={element.variant} readOnly></Input>
                        <Input value={element.color} readOnly></Input>
                        <Input value={element.index} readOnly></Input>
                        <Button danger onClick={async event => {
                            document.querySelector( ".sending-loader" ).style.display = "flex";
                            await deleteDoc( doc( firestore, "aapli_global/Trending Vehicles/Vehicles List", element.id ) );
                            document.querySelector( ".sending-loader" ).style.display = "none";
                        }}>Delete Vehicle</Button>
                    </div>
                ) )}
            </div>
        </div>
    )
}

export default TrendingVehicles