import React, { useEffect, useState } from 'react';
import "./Religion&SubReligion.css";
import { firestore, auth } from "../../firebase/firebaseConfig";
import { onAuthStateChanged} from 'firebase/auth';
import { updateDoc, doc, onSnapshot } from '@firebase/firestore';
import { Button, Input } from 'antd';

function ReligionSubReligion () {
    let [ religions, setReligions ] = useState( [] );

    async function onload () {
        onSnapshot( doc( firestore, "GlobalConfigurations", "Religion&SubReligion" ), ( snapshot ) => {
            let religionRawArray = [];
            Object.keys( snapshot.data()[ "Religion&SubReligion" ] ).forEach( ( element ) => {
                religionRawArray.push( element );
            } )
            setReligions( [ ...religionRawArray ] );
            document.querySelector( ".sending-loader" ).style.display = "none";
        } );

    }

    useEffect( () => {
        document.querySelector( ".sending-loader" ).style.display = "flex";
        document.querySelectorAll( ".ant-menu-item" )[ 11 ].click();
        onAuthStateChanged( auth, ( user ) => {
            if ( user ) {
                onload();
            } else {
                window.location.href = "/login";
            }
        } );
    }, [] );

    return (
        <div className='making-container'>
            <form onSubmit={async event => {
                event.preventDefault();
                document.querySelector( ".sending-loader" ).style.display = "flex";
                let object = {};
                religions.forEach( ( element ) => {
                    object[ element ] = {};
                } )
                object[ event.target.religionName.value ] = {}
                await updateDoc( doc( firestore, "GlobalConfigurations", "Religion&SubReligion" ), {
                    "Religion&SubReligion": object
                } )
                document.querySelector( ".sending-loader" ).style.display = "none";
            }} className="making-container-top-container">
                <Input style={{ width: "180px" }} type="text" name='religionName' placeholder='Enter Religion Name' />
                <Button htmlType="submit" className='source-button'>Add Religion</Button>
            </form>
            <div className="all-make-city-container" style={{ width: "95%" }}>
                Religion
            </div>
            <div className='all-make-entries-container'>
                {religions.map( ( element, index ) => (
                    <div style={{ alignItems: "center", width: "100%", justifyContent: "space-between" }} className='all-make-entry-container'>
                        <h5 style={{ color: "white" }}>{element}</h5>
                        <Button danger onClick={async event => {
                            document.querySelector( ".sending-loader" ).style.display = "flex";
                            religions.splice( index, 1 );
                            let object = {};
                            religions.forEach( ( element ) => {
                                object[ element ] = {};
                            } )
                            await updateDoc( doc( firestore, "GlobalConfigurations", "Religion&SubReligion" ), {
                                "Religion&SubReligion": object
                            } )
                            document.querySelector( ".sending-loader" ).style.display = "none";
                        }}>Delete Religion</Button>
                    </div>
                ) )}
            </div>
        </div>
    )
}

export default ReligionSubReligion