import React, { useState, useEffect } from 'react';
import './Affiliate.css';
import { firestore, auth } from '../../firebase/firebaseConfig';
import { collection, doc, setDoc, deleteDoc, getDoc, onSnapshot } from '@firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { Button, Input, Popconfirm, message } from 'antd';

function Affiliate () {
    let [ vendors, setVendors ] = useState( [] );
    let [ vendorObject, setVendorObject ] = useState( {} );
    const [ messageApi, contextHolder ] = message.useMessage();

    async function onLoad () {
        onSnapshot( collection( firestore, "affiliate-users" ), ( snapshot ) => {
            let vendorsArray = [];
            snapshot.forEach( ( element ) => {
                let vendorObject = {
                    "name": element.data().name,
                    "mobile": element.data().mobileNumber,
                    "id": element.id
                }
                vendorsArray.push( vendorObject );
            } );
            setVendors( [ ...vendorsArray ] );
            document.querySelector( ".sending-loader" ).style.display = "none";
        } );
    }

    const error = () => {
        messageApi.open( {
            type: 'error',
            content: 'Vendor already exists in the database.',
        } );
    };

    useEffect( () => {
        document.querySelector( ".sending-loader" ).style.display = "flex";
        document.querySelectorAll( ".ant-menu-item" )[ 3 ].click();
        onAuthStateChanged( auth, ( user ) => {
            if ( user ) {
                onLoad();
            } else {
                window.location.href = "/login";
            }
        } );
    }, [] )

    return (
        <div className="making-container">
            {contextHolder}
            <form className='making-container-top-container' onSubmit={async event => {
                event.preventDefault();
                document.querySelector( ".sending-loader" ).style.display = "flex";

                let response = await fetch( "https://aapli-main-server-1036279390366.asia-south1.run.app/getCustomToken?phoneNumber=91" + vendorObject.mobile );
                let data = await response.json();

                let checkDoc = await getDoc( doc( firestore, "affiliate-users", data.uid ) );

                if ( !checkDoc.exists() ) {
                    await setDoc( doc( firestore, "affiliate-users", data.uid ), {
                        name: vendorObject.name,
                        mobileNumber: vendorObject.mobile
                    } )
                } else {
                    error();
                }
                document.querySelector( ".sending-loader" ).style.display = "none";
            }}>
                <Input style={{ width: "180px" }} type="text" placeholder='Enter User Name' value={vendorObject.name} onChange={event => {
                    setVendorObject( { ...vendorObject, name: event.target.value } );
                }} required />
                <Input addonBefore="+91" maxLength={10} style={{ width: "180px" }} type="text" placeholder='Enter User Mobile' value={vendorObject.mobile} onChange={event => {
                    if ( !isNaN( event.target.value ) ) {
                        setVendorObject( { ...vendorObject, mobile: event.target.value } );
                    }
                }} required />
                <Button htmlType='submit'>Add User</Button>
            </form>
            <div className='all-make-entries-container'>
                {vendors.map( ( element ) => (
                    <div className='all-make-entry-container'>
                        <input type="text" value={element.name} readOnly />
                        <input type="text" value={element.mobile} readOnly />
                        <Popconfirm
                            title="Delete the Vendor"
                            description={"Are you sure to delete " + element.name + "?"}
                            onConfirm={async event => {
                                await deleteDoc( doc( firestore, "affiliate-users", element.id ) );
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger>Delete User</Button>
                        </Popconfirm>
                    </div>
                ) )}
            </div >
        </div >
    )
}

export default Affiliate