import React, { useEffect, useState } from 'react';
import './Customers.css';
import  secureLocalStorage  from  "react-secure-storage";
import { firestore, auth } from '../../firebase/firebaseConfig';
import { collection, getDocs } from '@firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

function Customers() {
    let [customers, setCustomers] = useState([]);

    useEffect(() => {
        onAuthStateChanged( auth, ( user ) => {
            if ( user ) {
                console.log(user);
            } else {
                window.location.href = "/login";
            }
        } );
        onLoad();
    }, [])

    async function onLoad() {
        let companyId = secureLocalStorage.getItem("customers_company_id");

        let querySnapshot = await getDocs(collection(firestore, "company/"+companyId+"/customer"));

        let customersArray = [];
        querySnapshot.forEach((element) => {
            let customerObject = {
                "name": element.data().name,
                "phone": element.data().mobile,
                "comment": element.data().comment
            }
            customersArray.push(customerObject);
        });
        setCustomers([...customersArray]);
    }    

    return (
        <div className='customers-container'>
            <div className="customers-container-head">
                Customers
            </div>
            <div className="customers-container-content">
                {customers.map((element, index) => (
                    <div className='customer-card' key={index}>
                        <div className='customer-name'>
                            <div>Name - </div>
                            <div>{element.name}</div>
                        </div>
                        <div className='customer-phone'>
                            <div>Phone - </div>
                            <div>{element.phone}</div>
                        </div>
                        <div className='customer-comment'>
                            <div>Note - </div>
                            <div>{element.comment}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Customers