import React, { useEffect, useState } from 'react';
import "./InsuranceCompanies.css";
import { firestore, auth } from "../../firebase/firebaseConfig";
import { setDoc, doc, onSnapshot } from '@firebase/firestore';
import { onAuthStateChanged } from "firebase/auth"
import { Button, Input } from 'antd';

function InsuranceCompanies () {
    let [ companies, setCompanies ] = useState( [] );

    async function onload () {
        onSnapshot( doc( firestore, "GlobalConfigurations/Insurance Companies" ), ( snapshot ) => {
            setCompanies( [ ...snapshot.data().companies ] );
            document.querySelector( ".sending-loader" ).style.display = "none";
        } );
    }

    useEffect( () => {
        document.querySelector( ".sending-loader" ).style.display = "flex";
        document.querySelectorAll( ".ant-menu-item" )[ 7 ].click();
        onAuthStateChanged( auth, ( user ) => {
            if ( user ) {
                onload();
            } else {
                window.location.href = "/login";
            }
        } );
    }, [] );

    return (
        <div className='making-container'>
            <form onSubmit={async event => {
                event.preventDefault();
                document.querySelector( ".sending-loader" ).style.display = "flex";
                let array = companies;
                array.push( event.target.company_name.value );

                await setDoc( doc( firestore, "GlobalConfigurations", "Insurance Companies" ), {
                    companies: array
                } )

                event.target.reset();
                document.querySelector( ".sending-loader" ).style.display = "none";
            }} className="making-container-top-container">
                <Input style={{ width: "180px" }} type="text" placeholder='Enter Company' className='company-name' name='company_name' required />
                <Button htmlType='submit'>Add Company</Button>
            </form>
            <div className="all-make-city-container" style={{ width: "95%" }}>
                Companies
            </div>
            <div className='all-make-entries-container'>
                {companies.map( ( element, index ) => (
                    <div style={{ alignItems: "center", width: "100%", justifyContent: "space-between" }} className='all-make-entry-container'>
                        <h5 style={{ color: "white" }}>{element}</h5>
                        <Button danger onClick={async event => {
                            document.querySelector( ".sending-loader" ).style.display = "flex";
                            companies.splice( index, 1 );

                            await setDoc( doc( firestore, "GlobalConfigurations", "Insurance Companies" ), {
                                companies: companies
                            } )
                            document.querySelector( ".sending-loader" ).style.display = "none";
                        }}>Delete Company</Button>
                    </div>
                ) )}
            </div>
        </div>
    )
}

export default InsuranceCompanies