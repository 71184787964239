import React, { useState, useEffect } from 'react';
import "./Accessories.css";
import { firestore, auth, storage } from '../../firebase/firebaseConfig';
import { collection, onSnapshot, doc, addDoc, deleteDoc, updateDoc } from '@firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { ref, uploadBytes, deleteObject } from "firebase/storage";
import { Button, Input, Select, message } from 'antd';

function Accessories () {
    let [ standardAccessoriesArray, setStandardAccessoriesArray ] = useState( [] );
    let [ additionalAccessoriesArray, setAdditionalAccessoriesArray ] = useState( [] );
    let [ addOnArray, setAddOnArray ] = useState( [] );
    let [ accessoriesObject, setAccessoriesObject ] = useState( {} );
    const [ messageApi, contextHolder ] = message.useMessage();

    async function getInfo () {
        onSnapshot( collection( firestore, "Accessories" ), ( snapshot ) => {
            let StandardAccessoriesRawArray = [];
            let AdditionalAccessoriesRawArray = [];
            let addOnRawArray = [];

            snapshot.forEach( ( element ) => {
                if ( element.data().category === "add_on" ) {
                    let addOnObject = {
                        "id": element.id,
                        "name": element.data().name,
                        "category": element.data().category,
                        "imageName": element.data().image,
                        "fixed_charge": element.data().fixed_charge,
                        "percentage_charge": element.data().percentage_charge,
                        description: element.data().description
                    }
                    addOnRawArray.push( addOnObject );
                } else if ( element.data().category === "standard" ) {
                    let accessoryObject = {
                        "id": element.id,
                        "name": element.data().name,
                        "category": element.data().category,
                        "imageName": element.data().image,
                        "marked_price": element.data().marked_price,
                        "selling_price": element.data().selling_price,
                        description: element.data().description
                    }
                    StandardAccessoriesRawArray.push( accessoryObject );
                } else {
                    let accessoryObject = {
                        "id": element.id,
                        "name": element.data().name,
                        "category": element.data().category,
                        "imageName": element.data().image,
                        "marked_price": element.data().marked_price,
                        "selling_price": element.data().selling_price,
                        description: element.data().description
                    }
                    AdditionalAccessoriesRawArray.push( accessoryObject );
                }
            } )
            setStandardAccessoriesArray( [ ...StandardAccessoriesRawArray ] );
            setAdditionalAccessoriesArray( [ ...AdditionalAccessoriesRawArray ] );
            setAddOnArray( [ ...addOnRawArray ] );
            document.querySelector( ".sending-loader" ).style.display = "none";
        } );
    }

    async function addAccessories ( event ) {
        messageApi.loading( "Saving Changes...", 0 );

        standardAccessoriesArray.forEach( async ( element ) => {
            if ( element.id !== undefined ) {
                await updateDoc( doc( firestore, "Accessories", element.id ), {
                    "name": element.name,
                    "image": element.imageName,
                    "marked_price": element.marked_price,
                    "selling_price": element.selling_price,
                    description: element.description
                } )

                if ( element.image ) {
                    const storageRef = ref( storage, "Accessories/" + element.imageName );
                    await uploadBytes( storageRef, element.image ).then( ( snapshot ) => {
                        console.log( 'Uploaded a blob or file!' );
                    } );
                }
            } else {
                await addDoc( collection( firestore, "Accessories" ), {
                    "name": element.name,
                    "category": element.category,
                    "image": element.imageName,
                    "marked_price": element.marked_price,
                    "selling_price": element.selling_price,
                    description: element.description
                } )

                const storageRef = ref( storage, "Accessories/" + element.imageName );
                await uploadBytes( storageRef, element.image ).then( ( snapshot ) => {
                    console.log( 'Uploaded a blob or file!' );
                } );
            }
        } )

        additionalAccessoriesArray.forEach( async ( element ) => {
            if ( element.id !== undefined ) {
                await updateDoc( doc( firestore, "Accessories", element.id ), {
                    "name": element.name,
                    "image": element.imageName,
                    "marked_price": element.marked_price,
                    "selling_price": element.selling_price,
                    description: element.description
                } )

                if ( element.image ) {
                    const storageRef = ref( storage, "Accessories/" + element.imageName );
                    await uploadBytes( storageRef, element.image ).then( ( snapshot ) => {
                        console.log( 'Uploaded a blob or file!' );
                    } );
                }
            } else {
                await addDoc( collection( firestore, "Accessories" ), {
                    "name": element.name,
                    "category": element.category,
                    "image": element.imageName,
                    "marked_price": element.marked_price,
                    "selling_price": element.selling_price,
                    description: element.description
                } )

                const storageRef = ref( storage, "Accessories/" + element.imageName );
                await uploadBytes( storageRef, element.image ).then( ( snapshot ) => {
                    console.log( 'Uploaded a blob or file!' );
                } );
            }
        } )

        addOnArray.forEach( async ( element ) => {
            if ( element.id !== undefined ) {
                await updateDoc( doc( firestore, "Accessories", element.id ), {
                    "name": element.name,
                    "image": element.imageName,
                    "fixed_charge": element.fixed_charge,
                    "percentage_charge": element.percentage_charge,
                    description: element.description
                } )

                if ( element.image ) {
                    const storageRef = ref( storage, "Accessories/" + element.imageName );
                    await uploadBytes( storageRef, element.image ).then( ( snapshot ) => {
                        console.log( 'Uploaded a blob or file!' );
                    } );
                }
            } else {
                await addDoc( collection( firestore, "Accessories" ), {
                    "name": element.name,
                    "category": element.category,
                    "image": element.imageName,
                    "fixed_charge": element.fixed_charge,
                    "percentage_charge": element.percentage_charge,
                    description: element.description
                } )

                const storageRef = ref( storage, "Accessories/" + element.imageName );
                await uploadBytes( storageRef, element.image ).then( ( snapshot ) => {
                    console.log( 'Uploaded a blob or file!' );
                } );
            }
        } )

        messageApi.destroy();
        messageApi.success( "Changes Saved." );
    }

    const error = () => {
        messageApi.open( {
            type: 'error',
            content: 'You need to choose a category before proceeding.',
        } );
    };

    const error1 = () => {
        messageApi.open( {
            type: 'error',
            content: 'You need upload an image before proceeding.',
        } );
    };

    useEffect( () => {
        document.querySelector( ".sending-loader" ).style.display = "flex";
        document.querySelectorAll( ".ant-menu-item" )[ 4 ].click();
        onAuthStateChanged( auth, ( user ) => {
            if ( user ) {
                getInfo();
            } else {
                window.location.href = "/login";
            }
        } );
    }, [] );

    return (
        <div className='making-container'>
            {contextHolder}
            <form className='making-container-top-container' onSubmit={event => {
                document.querySelector( ".sending-loader" ).style.display = "flex";
                event.preventDefault();
                if ( accessoriesObject.category && accessoriesObject.image ) {
                    if ( accessoriesObject.category === "standard" ) {
                        standardAccessoriesArray.push( {
                            name: accessoriesObject.name,
                            category: accessoriesObject.category,
                            image: accessoriesObject.image,
                            imageName: accessoriesObject.imageName,
                            marked_price: accessoriesObject.marked_price,
                            selling_price: accessoriesObject.selling_price,
                            description: accessoriesObject.description
                        } )
                        setStandardAccessoriesArray( [ ...standardAccessoriesArray ] );
                    } else if ( accessoriesObject.category === "additional" ) {
                        additionalAccessoriesArray.push( {
                            name: accessoriesObject.name,
                            category: accessoriesObject.category,
                            image: accessoriesObject.image,
                            imageName: accessoriesObject.imageName,
                            marked_price: accessoriesObject.marked_price,
                            selling_price: accessoriesObject.selling_price,
                            description: accessoriesObject.description
                        } );
                        setAdditionalAccessoriesArray( [ ...additionalAccessoriesArray ] );
                    } else {
                        addOnArray.push( {
                            name: accessoriesObject.name,
                            category: accessoriesObject.category,
                            image: accessoriesObject.image,
                            imageName: accessoriesObject.imageName,
                            fixed_charge: accessoriesObject.fixed_charge,
                            percentage_charge: accessoriesObject.percentage_charge,
                            description: accessoriesObject.description
                        } );
                        setAddOnArray( [ ...addOnArray ] );
                    }
                    setAccessoriesObject( {} );
                    event.target.reset();
                } else if ( !accessoriesObject.category ) {
                    error();
                } else if ( !accessoriesObject.image ) {
                    error1();
                }
                document.querySelector( ".sending-loader" ).style.display = "none";
            }}>
                <input type="text" placeholder='Accessory Name' value={accessoriesObject.name} onChange={event => {
                    setAccessoriesObject( { ...accessoriesObject, name: event.target.value } );
                }} required />
                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                    allowClear
                    value={accessoriesObject.category ? accessoriesObject.category : "Select Category"}
                    placeholder="Select Category"
                    style={{ width: "150px" }}
                    onChange={async event => {
                        if ( event ) {
                            setAccessoriesObject( { ...accessoriesObject, category: event } );
                        } else {
                            delete accessoriesObject.category;
                            setAccessoriesObject( { ...accessoriesObject } );
                        }
                    }}
                    options={[
                        { label: "Standard", value: "standard" },
                        { label: "Additional", value: "additional" },
                        { label: "Insurance Add On", value: "add_on" },
                    ]}
                />
                <Button onClick={event => document.querySelector( ".accessory_upload_image" ).click()}>
                    {accessoriesObject.image ? "Uploaded" : "Upload Accessory Image"}
                </Button>
                <input style={{ display: "none" }} type="file" className='accessory_upload_image' accept="image/png, image/jpeg" onChange={event => {
                    accessoriesObject.image = event.target.files[ 0 ];
                    accessoriesObject.imageName = event.target.files[ 0 ].name;
                    setAccessoriesObject( { ...accessoriesObject } );
                }} />
                {accessoriesObject.category !== "add_on" && <input type="text" placeholder='Marked Price' value={accessoriesObject.marked_price} onChange={event => {
                    if ( !isNaN( event.target.value ) ) {
                        setAccessoriesObject( { ...accessoriesObject, marked_price: event.target.value } );
                    } else {
                        event.target.value = accessoriesObject.marked_price;
                    }
                }} required />}
                {accessoriesObject.category !== "add_on" && <input type="text" placeholder='Selling Price' value={accessoriesObject.selling_price} onChange={event => {
                    if ( !isNaN( event.target.value ) ) {
                        setAccessoriesObject( { ...accessoriesObject, selling_price: event.target.value } );
                    } else {
                        event.target.value = accessoriesObject.selling_price;
                    }
                }} required />}
                {accessoriesObject.category === "add_on" && <input type="text" placeholder='Fixed Charge' value={accessoriesObject.fixed_price} onChange={event => {
                    if ( !isNaN( event.target.value ) ) {
                        setAccessoriesObject( { ...accessoriesObject, fixed_charge: event.target.value } );
                    } else {
                        event.target.value = accessoriesObject.fixed_price;
                    }
                }} required />}
                {accessoriesObject.category === "add_on" && <input type="text" placeholder='Percentage Charge' onChange={event => {
                    if ( !isNaN( event.target.value ) ) {
                        setAccessoriesObject( { ...accessoriesObject, percentage_charge: event.target.value } );
                    } else {
                        event.target.value = accessoriesObject.percentage_charge;
                    }
                }} required />}
                <input type="text" placeholder='Enter Description' onChange={event => {
                    setAccessoriesObject( { ...accessoriesObject, description: event.target.value } );
                }} required />
                <Button htmlType='submit'>Add Accessory</Button>
            </form>
            <div className='all-make-entries-container'>
                <div className='all-make-city-container'>
                    <span>Standard Accessories</span>
                    <Button onClick={event => addAccessories( event )}>Save Accessories</Button>
                </div>
                {standardAccessoriesArray.map( ( element, index ) => (
                    <div className='all-make-entry-container'>
                        <Input placeholder='Enter Name' value={element.name} onChange={event => {
                            standardAccessoriesArray[ index ].name = event.target.value;
                            setStandardAccessoriesArray( [ ...standardAccessoriesArray ] );
                        }}></Input>
                        <Input placeholder='Enter Category' value={element.category} readOnly></Input>
                        <Button style={{ minWidth: "180px", maxWidth: "180px" }} onClick={event => document.querySelectorAll( ".accessory-edit-image" )[ index ].click()}>{element.imageName}</Button>
                        <input style={{ display: "none" }} type="file" className='accessory-edit-image' onChange={event => {
                            standardAccessoriesArray[ index ].image = event.target.files[ 0 ];
                            standardAccessoriesArray[ index ].imageName = event.target.files[ 0 ].name;
                            setStandardAccessoriesArray( [ ...standardAccessoriesArray ] );
                        }} />
                        <Input placeholder='Enter Marked Price' value={element.marked_price} onChange={event => {
                            standardAccessoriesArray[ index ].marked_price = event.target.value;
                            setStandardAccessoriesArray( [ ...standardAccessoriesArray ] );
                        }}></Input>
                        <Input placeholder='Enter Selling Price' value={element.selling_price} onChange={event => {
                            standardAccessoriesArray[ index ].selling_price = event.target.value;
                            setStandardAccessoriesArray( [ ...standardAccessoriesArray ] );
                        }}></Input>
                        <Input placeholder='Enter Description' value={element.description} onChange={event => {
                            standardAccessoriesArray[ index ].description = event.target.value;
                            setStandardAccessoriesArray( [ ...standardAccessoriesArray ] );
                        }}></Input>
                        <Button danger onClick={async event => {
                            document.querySelector( ".sending-loader" ).style.display = "flex";
                            if ( element.id ) {
                                await deleteDoc( doc( firestore, "Accessories", element.id ) )
                                const storageRef = ref( storage, "Accessories/" + element.imageName );
                                await deleteObject( storageRef, element.image ).then( ( snapshot ) => {
                                    console.log( 'Deleted a blob or file!' );
                                } );
                            } else {
                                standardAccessoriesArray.splice( index, 1 );
                                setAddOnArray( [ ...setStandardAccessoriesArray ] );
                            }
                            document.querySelector( ".sending-loader" ).style.display = "none";
                        }}>Delete Accessory</Button>
                    </div>
                ) )}
                <div style={{ marginTop: "2rem" }} className='all-make-city-container'>
                    <span>Additional Accessories</span>
                    <Button onClick={event => addAccessories( event )}>Save Accessories</Button>
                </div>
                {additionalAccessoriesArray.map( ( element, index ) => (
                    <div className='all-make-entry-container'>
                        <Input placeholder='Enter Name' value={element.name} onChange={event => {
                            additionalAccessoriesArray[ index ].name = event.target.value;
                            setAdditionalAccessoriesArray( [ ...additionalAccessoriesArray ] );
                        }}></Input>
                        <Input placeholder='Enter Category' value={element.category} readOnly></Input>
                        <Button style={{ minWidth: "180px", maxWidth: "180px" }} onClick={event => document.querySelectorAll( ".additional-accessory-edit-image" )[ index ].click()}>{element.imageName}</Button>
                        <input style={{ display: "none" }} type="file" className='additional-accessory-edit-image' onChange={event => {
                            additionalAccessoriesArray[ index ].image = event.target.files[ 0 ];
                            additionalAccessoriesArray[ index ].imageName = event.target.files[ 0 ].name;
                            setAdditionalAccessoriesArray( [ ...additionalAccessoriesArray ] );
                        }} />
                        <Input placeholder='Enter Marked Price' value={element.marked_price} onChange={event => {
                            additionalAccessoriesArray[ index ].marked_price = event.target.value;
                            setAdditionalAccessoriesArray( [ ...additionalAccessoriesArray ] );
                        }}></Input>
                        <Input placeholder='Enter Selling Price' value={element.selling_price} onChange={event => {
                            additionalAccessoriesArray[ index ].selling_price = event.target.value;
                            setAdditionalAccessoriesArray( [ ...additionalAccessoriesArray ] );
                        }}></Input>
                        <Input placeholder='Enter Description' value={element.description} onChange={event => {
                            additionalAccessoriesArray[ index ].description = event.target.value;
                            setAdditionalAccessoriesArray( [ ...additionalAccessoriesArray ] );
                        }}></Input>
                        <Button danger onClick={async event => {
                            document.querySelector( ".sending-loader" ).style.display = "flex";
                            if ( element.id ) {
                                await deleteDoc( doc( firestore, "Accessories", element.id ) )
                                const storageRef = ref( storage, "Accessories/" + element.imageName );
                                await deleteObject( storageRef, element.image ).then( ( snapshot ) => {
                                    console.log( 'Deleted a blob or file!' );
                                } );
                            } else {
                                additionalAccessoriesArray.splice( index, 1 );
                                setAddOnArray( [ ...additionalAccessoriesArray ] );
                            }
                            document.querySelector( ".sending-loader" ).style.display = "none";
                        }}>Delete Accessory</Button>
                    </div>
                ) )}
                <div style={{ marginTop: "2rem" }} className='all-make-city-container'>
                    <span>Insurance Add Ons</span>
                    <Button onClick={event => addAccessories( event )}>Save Accessories</Button>
                </div>
                {addOnArray.map( ( element, index ) => (
                    <div className='all-make-entry-container'>
                        <div className='all-make-entry-container'>
                            <Input placeholder='Enter Name' value={element.name} onChange={event => {
                                addOnArray[ index ].name = event.target.value;
                                setAddOnArray( [ ...addOnArray ] );
                            }}></Input>
                            <Input placeholder='Enter Category' value={element.category} readOnly></Input>
                            <Button style={{ minWidth: "180px", maxWidth: "180px" }} onClick={event => document.querySelectorAll( ".addon-edit-image" )[ index ].click()}>{element.imageName}</Button>
                            <input style={{ display: "none" }} type="file" className='addon-edit-image' onChange={event => {
                                addOnArray[ index ].image = event.target.files[ 0 ];
                                addOnArray[ index ].imageName = event.target.files[ 0 ].name;
                                setAddOnArray( [ ...addOnArray ] );
                            }} />
                            <Input placeholder='Enter Fixed Charge' value={element.fixed_charge} onChange={event => {
                                addOnArray[ index ].fixed_charge = event.target.value;
                                setAddOnArray( [ ...addOnArray ] );
                            }}></Input>
                            <Input placeholder='Enter Percentage Charge' value={element.percentage_charge} onChange={event => {
                                addOnArray[ index ].percentage_charge = event.target.value;
                                setAddOnArray( [ ...addOnArray ] );
                            }}></Input>
                            <Input placeholder='Enter Description' value={element.description} onChange={event => {
                                addOnArray[ index ].description = event.target.value;
                                setAddOnArray( [ ...addOnArray ] );
                            }}></Input>
                            <Button danger onClick={async event => {
                                document.querySelector( ".sending-loader" ).style.display = "flex";
                                if ( element.id ) {
                                    await deleteDoc( doc( firestore, "Accessories", element.id ) )
                                    const storageRef = ref( storage, "Accessories/" + element.imageName );
                                    await deleteObject( storageRef, element.image ).then( ( snapshot ) => {
                                        console.log( 'Deleted a blob or file!' );
                                    } );
                                } else {
                                    addOnArray.splice( index, 1 );
                                    setAddOnArray( [ ...addOnArray ] );
                                }
                                document.querySelector( ".sending-loader" ).style.display = "none";
                            }}>Delete Accessory</Button>
                        </div>
                    </div>
                ) )}
            </div>
        </div>
    )
}

export default Accessories