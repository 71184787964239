import React, { useEffect } from 'react';
import { firestore, auth } from "../../firebase/firebaseConfig";
import { RecaptchaVerifier, signInWithPhoneNumber, onAuthStateChanged } from "firebase/auth";
import { getDoc, doc } from '@firebase/firestore';
import './Login.css';
import secureLocalStorage from 'react-secure-storage';

function Login() {

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                window.location.href = "/";
            } else {
                // const uid = user.uid;
            }
        });
    }, [])

    function getRecaptchaVerifier() {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                console.log("Captcha Resolved");
            }, defaultCountry: "IN"
        }, auth);
    }

    async function onSignInSubmit(event) {
        event.preventDefault();
        const phoneNumber = "+91"+document.querySelector(".phone_number").value;
        
        console.log(phoneNumber);
        let querySnapshot = await getDoc(doc(firestore, "super-admins", phoneNumber));
        
        if(querySnapshot.data() !== undefined) {
            document.querySelector(".sending_otp").style.display = "block";
            document.querySelectorAll(".email-button")[0].setAttribute("disabled", "");
            getRecaptchaVerifier();

            const appVerifier = window.recaptchaVerifier;

            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;
                    console.log("SMS Sent");
                    document.querySelector(".sending_otp").style.display = "none";
                    document.querySelector(".otp_sent_success").style.display = "block";
                    document.querySelector(".otp").style.display = "block";
                    document.querySelectorAll(".email-button")[0].style.display = "none";
                    document.querySelectorAll(".email-button")[1].style.display = "block";
                    setTimeout(() => {
                        document.querySelector(".otp_sent_success").style.display = "none";
                    }, 2000)
                // ...
                }).catch((error) => {
                    console.log(error);
                    console.log("not Sent");
                    document.querySelector(".phone_authentication_error").style.display = "block";
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                });
        } else {
            document.querySelector(".phone_error").style.display = "block";
            setTimeout(() => {
                document.querySelector(".phone_error").style.display = "none";
            }, 2000);
        }
    }

    function onOTPInput(event) {
        event.preventDefault();
        document.querySelector(".otp_verifying").style.display = "block";
        let otp_input = document.querySelector(".otp").value;
        console.log(otp_input);
        document.querySelectorAll(".email-button")[1].setAttribute("disabled", "");

        window.confirmationResult.confirm(otp_input).then(async(result) => {
            // User signed in successfully.
            const user = result.user;
            console.log("Created User - ", user);
            document.querySelector(".otp_verifying").style.display = "none";
            document.querySelector(".otp_verified").style.display = "block";
            secureLocalStorage.setItem( "login", true );
            secureLocalStorage.setItem( "loginTime", new Date() );
            setTimeout(() => {
                document.querySelector(".otp_verified").style.display = "none";
                window.location.href = "/";
            }, 3000)
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
            console.log("Not Created");
            console.log(error);
            document.querySelector(".otp_authentication_error").style.display = "block";
            setTimeout(() => {
                window.location.reload();
            }, 2000)
        });
    }

    return (
        <div className='login-body'>
            <div className="login">
                <h1>Login</h1>
                <form>
                    <div id="recaptcha-container"></div>
                    <input type="tel" className='phone_number' placeholder="Enter Phone Number" minLength={10} maxLength={10} autoFocus required />
                    <input type="number" className='otp' placeholder="Enter The OTP" />
                    <span className='sending_otp'>Sending OTP....</span>
                    <span className='otp_sent_success'>OTP has been sent to your Phone Number.</span>
                    <span className='otp_verifying'>Verifying OTP....</span>
                    <span className='otp_verified'>OTP Verified.</span>
                    <span className='phone_error'>Sorry you don't have access to the application, please add your number in the database</span>
                    <span className='phone_authentication_error'>Too Many Attempts. Please Try Again Later.</span>
                    <span className='otp_authentication_error'>Wrong Otp Try Again.</span>
                    <button onClick={event => onSignInSubmit(event)} type="submit" className="btn btn-primary btn-block btn-large email-button code-button">Send Code</button>
                    <button onClick={onOTPInput} type="submit" className="btn btn-primary btn-block btn-large email-button verify-button">Verify Code</button>
                </form>
            </div>
        </div>
    )
}

export default Login