import React, { useState, useEffect } from 'react';
import './Blogs.css';
import { firestore, auth, storage } from '../../firebase/firebaseConfig';
import { collection, doc, setDoc, deleteDoc, updateDoc, onSnapshot } from '@firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { Button, Input, Modal, Popconfirm, Select, Space, message } from 'antd';
import { deleteObject, ref, uploadBytes } from 'firebase/storage';
import JoditEditor from 'jodit-react';

function Blogs () {
    let [ blogs, setBlogs ] = useState( [] );
    let [ addModal, setAddModal ] = useState( false );
    let [ editModal, setEditModal ] = useState( false );
    let [ addObject, setAddObject ] = useState( {} );
    let [ editObject, setEditObject ] = useState( {} );
    const [ messageApi, contextHolder ] = message.useMessage();

    async function onLoad () {
        onSnapshot( collection( firestore, "blogs" ), ( snapshot ) => {
            let blogsArray = [];
            snapshot.forEach( ( element ) => {
                blogsArray.push( {
                    id: element.id,
                    data: element.data()
                } );
            } );
            setBlogs( [ ...blogsArray ] );
            document.querySelector( ".sending-loader" ).style.display = "none";
        } );
    }

    useEffect( () => {
        document.querySelector( ".sending-loader" ).style.display = "flex";
        document.querySelectorAll( ".ant-menu-item" )[ 14 ].click();
        onAuthStateChanged( auth, ( user ) => {
            if ( user ) {
                onLoad();
            } else {
                window.location.href = "/login";
            }
        } );
    }, [] )

    return (
        <div className="making-container">
            {contextHolder}
            <Modal title="Provide details of the blog" open={addModal} onOk={async () => {
                if ( addObject.title && addObject.category && addObject.short_description && addObject.description && addObject.image && addObject.urlSlug ) {
                    messageApi.loading( "Adding the Blog...", 0 );
                    await setDoc( doc( firestore, "blogs", addObject.urlSlug ), {
                        title: addObject.title,
                        meta_title: addObject.meta_title ?? "",
                        meta_description: addObject.meta_description ?? "",
                        category: addObject.category,
                        short_description: addObject.short_description,
                        description: addObject.description,
                        image: addObject.image.name,
                        urlSlug: addObject.urlSlug ?? "",
                        time: new Date()
                    } )
                    await uploadBytes( ref( storage, "blogs/" + addObject.urlSlug + "/" + addObject.image.name ), addObject.image );
                    messageApi.destroy();
                    messageApi.success( "Blog added successfully!" );
                    setAddObject( {} );
                    setAddModal( false )
                } else {
                    messageApi.error( "Enter all the fields before proceeding." );
                }
            }} onCancel={() => {
                setAddObject( {} );
                setAddModal( false )
            }} okText="Add Blog" centered width={"70%"}>
                <Space direction='vertical' style={{ width: "80%", padding: "1rem 0" }}>
                    <Input placeholder='Enter the Url Slug' value={addObject.urlSlug} onChange={event => {
                        setAddObject( { ...addObject, urlSlug: event.target.value } );
                    }}></Input>
                    <Input placeholder='Enter the title' value={addObject.title} onChange={event => {
                        setAddObject( { ...addObject, title: event.target.value } );
                    }}></Input>
                    <Input placeholder='Enter the Meta Title' value={addObject.meta_title} onChange={event => {
                        setAddObject( { ...addObject, meta_title: event.target.value } );
                    }}></Input>
                    <Input placeholder='Enter the Meta Description' value={addObject.meta_description} onChange={event => {
                        setAddObject( { ...addObject, meta_description: event.target.value } );
                    }}></Input>
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select the category"
                        value={addObject.category ?? []}
                        onChange={( event ) => {
                            setAddObject( { ...addObject, category: event } );
                        }}
                        style={{ width: "100%" }}
                        mode="multiple"
                        options={[
                            {
                                value: 'New',
                                label: 'New',
                            },
                            {
                                value: 'Updates',
                                label: 'Updates',
                            },
                            {
                                value: 'Launch',
                                label: 'Launch',
                            },
                        ]}
                    />
                    <Input placeholder='Enter the Short Description' value={addObject.short_description} onChange={event => {
                        setAddObject( { ...addObject, short_description: event.target.value } );
                    }}></Input>
                    <JoditEditor
                        value={addObject.description}
                        tabIndex={1}
                        onBlur={newContent => setAddObject( { ...addObject, description: newContent } )}
                        onChange={newContent => setAddObject( { ...addObject, description: newContent } )}
                    />
                    <Button style={{ width: "100%" }} onClick={() => {
                        document.querySelector( ".blog-image" ).click();
                    }}>Upload Image for Blog</Button>
                    <input type="file" accept='image/*' style={{ display: "none" }} className='blog-image' onChange={event => {
                        setAddObject( { ...addObject, image: event.target.files[ 0 ] } );
                    }} />
                </Space>
            </Modal>
            <Modal title="Edit details of the blog" open={editModal} onOk={async () => {
                messageApi.loading( "Editing the Blog...", 0 );
                let object = {
                    title: editObject.title,
                    meta_title: editObject.meta_title ?? "",
                    meta_description: editObject.meta_description ?? "",
                    category: editObject.category,
                    short_description: editObject.short_description,
                    description: editObject.description,
                    urlSlug: editObject.urlSlug ?? "",
                    time: new Date()
                };
                if ( editObject.editedImage ) {
                    object.image = editObject.editedImage.name;
                }
                updateDoc( doc( firestore, "blogs", editObject.id ), object )
                if ( object.image ) {
                    await uploadBytes( ref( storage, "blogs/" + editObject.id + "/" + editObject.editedImage.name ), editObject.editedImage );
                }
                messageApi.destroy();
                messageApi.success( "Blog edited successfully!" );
                setEditObject( {} );
                setEditModal( false );
            }} onCancel={() => {
                setEditObject( {} );
                setEditModal( false )
            }} okText="Save Details" centered width={"70%"}>
                <Space direction='vertical' style={{ width: "100%", padding: "1rem 0" }}>
                    <Input placeholder='Enter the Url Slug' value={editObject.urlSlug} onChange={event => {
                        setEditObject( { ...editObject, urlSlug: event.target.value } );
                    }} readOnly></Input>
                    <Input placeholder='Enter the title' value={editObject.title} onChange={event => {
                        setEditObject( { ...editObject, title: event.target.value } );
                    }}></Input>
                    <Input placeholder='Enter the Meta Title' value={editObject.meta_title} onChange={event => {
                        setEditObject( { ...editObject, meta_title: event.target.value } );
                    }}></Input>
                    <Input placeholder='Enter the Meta Description' value={editObject.meta_description} onChange={event => {
                        setEditObject( { ...editObject, meta_description: event.target.value } );
                    }}></Input>
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select the category"
                        value={editObject.category ?? []}
                        onChange={( event ) => {
                            setEditObject( { ...editObject, category: event } );
                        }}
                        style={{ width: "100%" }}
                        options={[
                            {
                                value: 'New',
                                label: 'New',
                            },
                            {
                                value: 'Updates',
                                label: 'Updates',
                            },
                            {
                                value: 'Launch',
                                label: 'Launch',
                            },
                        ]}
                        mode="multiple"
                    />
                    <Input placeholder='Enter the Short Description' value={editObject.short_description} onChange={event => {
                        setEditObject( { ...editObject, short_description: event.target.value } );
                    }}></Input>
                    <JoditEditor
                        value={editObject.description}
                        tabIndex={1}
                        onBlur={newContent => setEditObject( { ...editObject, description: newContent } )}
                        onChange={newContent => setEditObject( { ...editObject, description: newContent } )}
                    />
                    <Button style={{ width: "100%" }} onClick={() => {
                        document.querySelector( ".blog-image-edit" ).click();
                    }}>Upload Image for Blog</Button>
                    <input type="file" accept='image/*' style={{ display: "none" }} className='blog-image-edit' onChange={event => {
                        console.log( "changes" );
                        setEditObject( { ...editObject, editedImage: event.target.files[ 0 ] } );
                    }} />
                </Space>
            </Modal>
            <div className='all-make-entries-container'>
                {blogs.map( ( element ) => (
                    <div className='all-make-entry-container' style={{ width: '100%' }}>
                        <input type="text" value={element.data.title} style={{ width: "100%" }} readOnly />
                        <input type="text" value={element.data.category.toString()} readOnly />
                        <Button onClick={event => {
                            setEditObject( { ...element.data, id: element.id } );
                            setEditModal( true );
                        }}>Edit Blog</Button>
                        <Popconfirm
                            title="Delete the Vendor"
                            description={"Are you sure to delete " + element.data.title + "?"}
                            onConfirm={async event => {
                                messageApi.loading( "Deleting the Blog...", 0 );
                                await deleteObject( ref( storage, "/blogs/" + element.id + "/" + element.data.image ) );
                                await deleteDoc( doc( firestore, "blogs", element.id ) );
                                messageApi.destroy();
                                messageApi.success( "Blog deleted successfully!" );
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger>Delete Blog</Button>
                        </Popconfirm>
                    </div>
                ) )}
            </div >
            <Button onClick={() => setAddModal( true )}>+</Button>
        </div >
    )
}

export default Blogs